import './styles/style.css'
import useFieldRowOne from './useFieldRowOne'
import useFieldRowTwo from './useFieldRowTwo'
import useFieldRowThree from './useFieldRowThree'
import { useMemo } from 'react'

const useFieldRow = (props) => {
  const columnsOne = useFieldRowOne(props);
  const columnsTwo = useFieldRowTwo(props);
  const columnsThree = useFieldRowThree(props);

  const memoizeColumnsOne = useMemo(() => columnsOne, [columnsOne]);
  const memoizeColumnsTwo = useMemo(() => columnsTwo, [columnsTwo]);
  const memoizeColumnsThree = useMemo(() => columnsThree, [columnsThree]);

  const fieldsRowTable = {
    ...memoizeColumnsOne,
    ...memoizeColumnsTwo,
    ...memoizeColumnsThree
  }

  return { fieldsRowTable }
}

export default useFieldRow
