import { lazy } from 'react'
import { MemberMenuUrl } from '../constant'

const CustomerVisitDetail = lazy(() => import('../pages/Customer/CustomerVisitsDetail'))
const CustomerVisitOutlet = lazy(() => import('../pages/Customer/CustomerVisitOutlet'))
const CustomerVisit = lazy(() => import('../pages/CustomerVisit'))
const CustomerVisitCreate = lazy(() => import('../pages/CustomerVisitCreate'))
const CustomerVisitActivity = lazy(() => import('../pages/CustomerVisitActivity'))
const CustomerVisitReschedule = lazy(() => import('../pages/Customer/CustomerVisitReschedule'))
const CustomerVisitSellingDetailing = lazy(() => import('../pages/Customer/CustomerVisitSellingDetailing'))
const CustomerVisitCollection = lazy(() => import('../pages/Customer/CustomerVisitCollection'))
const CustomerVisitStockRecord = lazy(() => import('../pages/CustomerVisitStockRecord'))
const ReportMenu = lazy(() => import('../pages/ReportMenu'))
const MonthlySalesReport = lazy(() => import('../pages/MonthlySalesReport'))
const VisitReport = lazy(() => import('../pages/VisitReport'))
const AccountProfile = lazy(() => import('../pages/AccountProfile'))
const Download = lazy(() => import('../pages/Download'))
const AccountChangePassword = lazy(() => import('../pages/AccountChangePassword'))
const AccountReminder = lazy(() => import('../pages/AccountReminder'))
const ChangeField = lazy(() => import('../pages/ChangeField'))
const CustomerManagement = lazy(() => import('../pages/CustomerManagement'))
const CustomerManagementUpdate = lazy(() => import('../pages/CustomerManagementUpdate'))
const CustomerVisitVirtualForm = lazy(() => import('../pages/CustomerVisitVirtualForm'))
const MerchandiseProductList = lazy(() => import('../pages/MerchandiseProductList'))
const MerchandiseProductDetail = lazy(() => import('../pages/MerchandiseProductDetail'))
const MerchandiseProductCompetitor = lazy(() => import('../pages/MerchandiseProductCompetitor'))
const CustomerVisitReturnProduct = lazy(() => import('../pages/CustomerVisitReturnProduct'))
const CustomerVisitReturnProductDetail = lazy(() => import('../pages/CustomerVisitReturnProductDetail'))
const CustomerVisitCustomerSurvey = lazy(() => import('../pages/CustomerVisitCustomerSurvey'))
const CustomerVisitCustomerSurveyDetail = lazy(() => import('../pages/CustomerVisitCustomerSurveyDetail'))
const CollectionCollected = lazy(() => import('../pages/CollectionCollected'))
const CollectionCollectedHistory = lazy(() => import('../pages/CollectionCollectedHistory'))
const CollectionCollectedInvoiceList = lazy(() => import('../pages/CollectionCollectedInvoiceList'))
const CollectionCollectedAditionalCost = lazy(() => import('../pages/CollectionCollectedAditionalCost'))
const CollectionCollectedAditionalList = lazy(() => import('../pages/CollectionCollectedAditionalList'))
const CollectionReportOutlet = lazy(() => import('../pages/CollectionTrendReport'))
const MonthlyCollectionReport = lazy(() => import('../pages/MonthlyCollectionReport'))
const InvoiceListByOutlet = lazy(() => import('../pages/InvoiceListByOutlet'))
const CustomerVisitCallSummary = lazy(() => import('../pages/CustomerVisitCallSummary'))
const CustomerVisitOrderTaking = lazy(() => import('../pages/CustomerVisitOrderTaking'))
const AVR = lazy(() => import('../pages/AVR/survey/avr'))
const AVRReport = lazy(() => import('../pages/AVR/report/avr-report'))
const Services = lazy(() => import('../pages/ServicesBar'))
const ARData = lazy(() => import('../pages/ARData'))
const Econtract = lazy(() => import('../pages/Econtract'))
const TenderQuota = lazy(() => import('../pages/TenderQuota'))
const Telesales = lazy(() => import('../pages/Tele-Seller'))
const TelesalesSummary = lazy(() => import('../pages/TeleSalesSummary'))
const UtilityReport = lazy(() => import('../pages/UtilityReport/UtilityReport'))
// Add new


const CustomerVisitLocationNotAccurate = lazy(() => import('../pages/CustomerVisitLocationNotAccurate'))
const VisitPlanTeam = lazy(() => import('../pages/VisitPlanTeam'))
const VisitPlanTeamRejectedDetail = lazy(() => import('../pages/VisitPlanTeamRejectedDetail'))
const VisitPlanTeamApprovedDetail = lazy(() => import('../pages/VisitPlanTeamApprovedDetail'))
const VisitPlanTeamRequestDetail = lazy(() => import('../pages/VisitPlanTeamRequestDetail'))
const Product = lazy(() => import('../pages/Product'))
const ProductList = lazy(() => import('../pages/ProductList'))
const BrandList = lazy(() => import('../pages/BrandList'))
const Customer = lazy(() => import('../pages/Customer'))
const OrderTracking = lazy(() => import('../pages/OrderTracking'))
const OrderTrackingOutlet = lazy(() => import('../pages/OrderTrackingOutlet'))
const VisitComplianceReport = lazy(() => import('../pages/VisitComplianceReport'))
const VisitComplianceReportDetail = lazy(() => import('../pages/VisitComplianceReportDetail'))
const PromoReport = lazy(() => import('../pages/PromoReport'))
const PromoReportDetail = lazy(() => import('../pages/PromoReportDetail'))
const TerritoryManagement = lazy(() => import('../pages/TerritoryManagement'))
const TerritoryManagementCreate = lazy(() => import('../pages/TerritoryManagementCreate'))
const TerritoryManagementUpdate = lazy(() => import('../pages/TerritoryManagementUpdate'))
const VisitReportCallActivities = lazy(() => import('../pages/CallReport/VisitReportCallActivities'))
const CallByReportImages = lazy(() => import('../components/report/visitreport/CallByReportImages'))
const SalesReportPrincipal = lazy(() => import('../pages/SalesReport'))
const MerchandiseProductCompetitorCreate = lazy(() => import('../pages/MerchandiseProductCompetitionCreate'))
const MerchandiseProductCreate = lazy(() => import('../pages/MerchandiseProductCreate'))
const SalesmanActivity = lazy(() => import('../pages/SalesmanActivity'))
const SalesmanTrackingReport = lazy(() => import('../pages/SalesmanTrackingReport'))
const TargetManagement = lazy(() => import('../pages/TargetManagement'))
const IncentiveReport = lazy(() => import('../pages/IncentiveReport'))
const CallPlanningApprove = lazy(() => import('../pages/CallPlanningApprove/CallPlanningApprove'))
const CallPlanningCoaching = lazy(() => import('../pages/CallPlanningApprove/CallPlanningCoaching'))

const CallPlanningPlanVisit = lazy(() => import('../pages/CallPlanning/CallPlanningPlanVisit'))
const CallPlanning = lazy(() => import('../pages/CallPlanning/CallPlanning'))

const FLMMenu = lazy(() => import('../pages/FLM/FLMMenu'))
const FLMActualTimeSheet = lazy(() => import('../pages/FLM/FLMActualTimeSheet'))
const FLMPlanTimeSheet = lazy(() => import('../pages/FLM/FLMPlanTimeSheet'))
const FLMPlanTimeSheetDetail = lazy(() => import('../pages/FLM/FLMPlanTimeSheetDetail'))
const FLMCoachingForm = lazy(() => import('../pages/FLM/FLMCoachingForm'))
const FLMAcknowledgement = lazy(() => import('../pages/FLM/FLMAcknowledgement'))
const FLMActualTimeSheetCalender = lazy(() => import('../pages/FLM/FLMActualTimeSheetCalender'))
const FLMReport = lazy(() => import('../pages/FLM/FLMReport'))
const SLMReport = lazy(() => import('../pages/SLM/SLMReport'))
const ApplicationSyncReport = lazy(() => import('../pages/ApplicationSyncReport'))
const CallPlanReport = lazy(() => import('../pages/CallPlanReport/CallPlanReport'))
const LocationMappedReport = lazy(() => import('../pages/CustomerReport/CustomerReportTab'))
const CustomerReport = lazy(() => import('../pages/CustomerReport/CustomerReport'))
const TeleSellerReport = lazy(() => import('../pages/TeleSellerReports/TeleSellerReport'))
const ConsignmentReport = lazy(() => import('../pages/ConsignmentReport/ConsignmentReport'))

const DashboardMenu = lazy(() => import(`../pages/Dashboard/DashboardMenu`))
// const Dashboard                            = lazy(() => import('../pages/Dashboard/Dashboard'))
const CallPlanDashboard = lazy(() => import('../pages/Dashboard/CallPlanning'))
const OrderRecommendationDashboard = lazy(() => import('../pages/Dashboard/OrderRecommendation'))
const DashboardandProductivityReport = lazy(() => import('../pages/Dashboard/DashboardandProductivityReport'))
const CandTDashboard = lazy(() => import('../pages/Dashboard/CnTDashboard'))
const CallPlanPowerBi = lazy(() => import('../pages/Dashboard/CallPlanPowerBi'))
const LookerValidation = lazy(() => import('../pages/Dashboard/LookerValidation'))
const SalesTargetSetting = lazy(() => import('../pages/Dashboard/SalesTargetSetting'))
const CustomerActualSales = lazy(() => import('../pages/Dashboard/CustomerActualSales'))
const ClassificationTargetingOutput_MainOffice = lazy(() => import('../pages/Dashboard/ClassificationTargetingOutput_Main Office'))
const ClassificationTargetingOutput_Branch = lazy(() => import('../pages/Dashboard/ClassificationTargetingOutput_Branch'))
const DocumentLibrary = lazy(() => import('../pages/DocumentLibrary'))
const SlmCalendarView = lazy(() => import('../pages/SLMCalendar/SlmCalendarView'))
const SlmDetails = lazy(() => import('../pages/SLMCalendar/SlmCalendarView/SlmDetails/SlmDetails'))
const CallHistory = lazy(() => import('../pages/Customer/CustomerVisitsDetail/CallHistory'))
const CallPlanningMenu = lazy(() => import('../pages/CallPlanningApprove/CallPlanningMenu'))
const TenderManagement = lazy(() => import('../pages/TenderManagement/index'))

export const routesAuthen = [
    {
        roleAccess: MemberMenuUrl.ALL,
        listRoute: [
            { urlRoute: '/customer-visit', subComponent: CustomerVisit, exact: true },
            { urlRoute: '/customer-visit/detail/:id', subComponent: CustomerVisitDetail },
            { urlRoute: '/customer-visit/create', subComponent: CustomerVisitCreate },
            { urlRoute: '/customer-visit/reschedule', subComponent: CustomerVisitReschedule },
            { urlRoute: '/customer-visit/outlet', subComponent: CustomerVisitOutlet },
            { urlRoute: '/customer-visit/product-list', subComponent: ProductList },
            { urlRoute: '/customer-visit/brand-list', subComponent: BrandList },

            { urlRoute: '/customer-visit/visit-activity', subComponent: CustomerVisitActivity, exact: true },
            { urlRoute: '/customer-visit/visit-activity/collection', subComponent: CustomerVisitCollection },
            { urlRoute: '/customer-visit/visit-activity/Telesales/:id', subComponent: Telesales, exact: true},
            { urlRoute: '/customer-visit/visit-activity/stock-record', subComponent: CustomerVisitStockRecord },
            { urlRoute: '/customer-visit/visit-activity/virtual-visit', subComponent: CustomerVisitVirtualForm },
            { urlRoute: '/customer-visit/visit-activity/selling-detailing', subComponent: CustomerVisitSellingDetailing },
            { urlRoute: '/customer-visit/visit-activity/merchandise', subComponent: MerchandiseProductList, exact: true },
            { urlRoute: '/customer-visit/visit-activity/merchandise/product-detail/:id', subComponent: MerchandiseProductDetail },
            { urlRoute: '/customer-visit/visit-activity/merchandise/product-competitor/:id', subComponent: MerchandiseProductCompetitor },
            { urlRoute: '/customer-visit/visit-activity/merchandise/product-competitor-create/:id', subComponent: MerchandiseProductCompetitorCreate },
            { urlRoute: '/customer-visit/visit-activity/merchandise/product-detail-create/:id', subComponent: MerchandiseProductCreate },
            { urlRoute: '/customer-visit/visit-activity/return-product', subComponent: CustomerVisitReturnProduct, exact: true },
            { urlRoute: '/customer-visit/visit-activity/return-product/detail', subComponent: CustomerVisitReturnProductDetail },
            { urlRoute: '/customer-visit/visit-activity/customer-survey/detail/:id', subComponent: CustomerVisitCustomerSurveyDetail },
            { urlRoute: '/customer-visit/visit-activity/customer-survey', subComponent: CustomerVisitCustomerSurvey },
            { urlRoute: '/customer-visit/visit-activity/call-summary', subComponent: CustomerVisitCallSummary, exact: true },
            { urlRoute: '/customer-visit/visit-activity/call-summary/location-not-accurate', subComponent: CustomerVisitLocationNotAccurate },
            { urlRoute: '/customer-visit/visit-activity/services', subComponent: Services, exact: true },
            { urlRoute: '/customer-visit/visit-activity/order-taking', subComponent: CustomerVisitOrderTaking, exact: true },
            { urlRoute: '/customer-visit/visit-activity/:category', subComponent: AVR },
            { urlRoute: '/customer-visit/visit-activity/avr', subComponent: AVR, exact: true },
            { urlRoute: '/customer-visit/call-history', subComponent: CallHistory, exact: true },


            { urlRoute: '/reports', subComponent: ReportMenu, exact: true },
            { urlRoute: '/document_library', subComponent: DocumentLibrary, exact: true },
            { urlRoute: '/reports/monthly-sales-report', subComponent: MonthlySalesReport },
            { urlRoute: '/reports/call-report', subComponent: VisitReport, exact: true },
            { urlRoute: '/reports/call-report/:tab', subComponent: VisitReportCallActivities },

            { urlRoute: '/collection-collected', subComponent: CollectionCollected, exact: true },
            { urlRoute: '/collection-collected/history-list/:id', subComponent: CollectionCollectedHistory },
            { urlRoute: '/collection-collected/invoice-list', subComponent: CollectionCollectedInvoiceList },
            { urlRoute: '/collection-collected/aditional-cost', subComponent: CollectionCollectedAditionalCost },
            { urlRoute: '/collection-collected/aditional-list/:id', subComponent: CollectionCollectedAditionalList },

            { urlRoute: '/reports/collection-trend-report', subComponent: CollectionReportOutlet },
            { urlRoute: '/reports/visit-compliance-report', subComponent: VisitComplianceReport, exact: true },
            { urlRoute: '/reports/visit-compliance-report/detail', subComponent: VisitComplianceReportDetail },
            { urlRoute: '/reports/promo-report', subComponent: PromoReport, exact: true },
            { urlRoute: '/reports/promo-report/detail', subComponent: PromoReportDetail },
            { urlRoute: '/reports/incentive-report', subComponent: IncentiveReport },

            { urlRoute: '/reports/monthly-collection-report/outlet/invoice-list/:slug', subComponent: InvoiceListByOutlet },

            { urlRoute: '/visit-plan-team', subComponent: VisitPlanTeam, exact: true },
            { urlRoute: '/visit-plan-team/rejected-detail/:id', subComponent: VisitPlanTeamRejectedDetail },
            { urlRoute: '/visit-plan-team/approved-detail/:id', subComponent: VisitPlanTeamApprovedDetail },
            { urlRoute: '/visit-plan-team/request-detail/:id', subComponent: VisitPlanTeamRequestDetail },

            { urlRoute: '/account/change-password', subComponent: AccountChangePassword },
            { urlRoute: '/account/reminder', subComponent: AccountReminder },
            { urlRoute: '/account/change', subComponent: ChangeField },
            { urlRoute: '/account', subComponent: AccountProfile, exact: true },

            { urlRoute: '/customer-management', subComponent: Customer, exact: true },
            { urlRoute: '/customer-management-update/:id', subComponent: CustomerManagementUpdate },
            { urlRoute: '/customer-management/customer-management', subComponent: CustomerManagement },
            { urlRoute: '/customer-management/order-tracking', subComponent: OrderTracking },
            { urlRoute: '/customer-management/order-tracking-outlet/:id', subComponent: OrderTrackingOutlet },

            { urlRoute: '/product-list', subComponent: Product },

            { urlRoute: '/reports/sales-trend-report/:category', subComponent: SalesReportPrincipal, exact: true },
            { urlRoute: '/reports/monthly-collection-report', subComponent: MonthlyCollectionReport, exact: true },

            { urlRoute: '/salesman-activity', subComponent: SalesmanActivity, exact: true },
            { urlRoute: '/salesman-activity/territory-management', subComponent: TerritoryManagement, exact: true },
            { urlRoute: '/salesman-activity/territory-management/create', subComponent: TerritoryManagementCreate },
            { urlRoute: '/salesman-activity/territory-management/detail/:id', subComponent: TerritoryManagementUpdate },
            { urlRoute: '/salesman-activity/salesman-tracking-report', subComponent: SalesmanTrackingReport },

            { urlRoute: '/target-management', subComponent: TargetManagement, exact: true },

            { urlRoute: '/call-planning', subComponent: CallPlanning },
            { urlRoute: '/call-planning-plan-visit', subComponent: CallPlanningPlanVisit },
            { urlRoute: '/call-planning/manager', subComponent: CallPlanningApprove, exact: true },

            { urlRoute: '/download', subComponent: Download, exact: true },
            { urlRoute: '/document_library', subComponent: DocumentLibrary, exact: true },
            { urlRoute: '/ar-data', subComponent: ARData, exact: true },
            { urlRoute: '/eContract', subComponent: Econtract, exact: true },
            { urlRoute: '/TenderQuota', subComponent: TenderQuota, exact: true },
           
            { urlRoute: '/Telesales-summary/:id', subComponent: TelesalesSummary },
            { urlRoute: '/slmcalendar', subComponent: SlmCalendarView, exact: true },
            { urlRoute: '/coaching-module/coaching-acknowledgement', subComponent: FLMAcknowledgement, exact: true },
            { urlRoute: '/coaching-module/coaching-acknowledgement/coaching-form', subComponent: FLMCoachingForm, exact: true },

            { urlRoute: '/customer-visit/visit-activity/tenderManagement', subComponent: TenderManagement, exact: true },

        ]
    },
    {
        roleAccess: MemberMenuUrl.CUSTOMER_VISIT,
        listRoute: [
            { urlRoute: '/customer-visit', subComponent: CustomerVisit, exact: true },
            { urlRoute: '/customer-visit/detail/:id', subComponent: CustomerVisitDetail },
            { urlRoute: '/customer-visit/create/customer', subComponent: CustomerVisitCreate },
            { urlRoute: '/customer-visit/create/work', subComponent: CustomerVisitCreate },
            { urlRoute: '/customer-visit/reschedule', subComponent: CustomerVisitReschedule },
            { urlRoute: '/customer-visit/outlet', subComponent: CustomerVisitOutlet },
            { urlRoute: '/customer-visit/product-list', subComponent: ProductList },
            { urlRoute: '/customer-visit/brand-list', subComponent: BrandList },
            { urlRoute: '/customer-visit/visit-activity', subComponent: CustomerVisitActivity, exact: true },
            { urlRoute: '/customer-visit/visit-activity/collection', subComponent: CustomerVisitCollection },
            { urlRoute: '/customer-visit/visit-activity/Telesales/:id', subComponent: Telesales ,exact: true},
            { urlRoute: '/customer-visit/visit-activity/stock-record', subComponent: CustomerVisitStockRecord },
            { urlRoute: '/customer-visit/visit-activity/virtual-visit', subComponent: CustomerVisitVirtualForm },
            { urlRoute: '/customer-visit/visit-activity/selling-detailing', subComponent: CustomerVisitSellingDetailing },
            { urlRoute: '/customer-visit/visit-activity/merchandise', subComponent: MerchandiseProductList, exact: true },
            { urlRoute: '/customer-visit/visit-activity/merchandise/product-detail/:id', subComponent: MerchandiseProductDetail },
            { urlRoute: '/customer-visit/visit-activity/merchandise/product-competitor/:id', subComponent: MerchandiseProductCompetitor },
            { urlRoute: '/customer-visit/visit-activity/merchandise/product-competitor-create/:id', subComponent: MerchandiseProductCompetitorCreate },
            { urlRoute: '/customer-visit/visit-activity/merchandise/product-detail-create/:id', subComponent: MerchandiseProductCreate },
            { urlRoute: '/customer-visit/visit-activity/return-product', subComponent: CustomerVisitReturnProduct, exact: true },
            { urlRoute: '/customer-visit/visit-activity/return-product/detail', subComponent: CustomerVisitReturnProductDetail },
            { urlRoute: '/customer-visit/visit-activity/customer-survey/detail/:id', subComponent: CustomerVisitCustomerSurveyDetail },
            { urlRoute: '/customer-visit/visit-activity/customer-survey', subComponent: CustomerVisitCustomerSurvey },
            { urlRoute: '/customer-visit/visit-activity/call-summary', subComponent: CustomerVisitCallSummary, exact: true },
            { urlRoute: '/customer-visit/visit-activity/call-summary/location-not-accurate', subComponent: CustomerVisitLocationNotAccurate },
            { urlRoute: '/customer-visit/visit-activity/services', subComponent: Services, exact: true },
            { urlRoute: '/customer-visit/visit-activity/tenderManagement', subComponent: TenderManagement, exact: true },
            { urlRoute: '/customer-visit/visit-activity/order-taking', subComponent: CustomerVisitOrderTaking, exact: true },
            { urlRoute: '/customer-visit/visit-activity/:category', subComponent: AVR },
           
            { urlRoute: '/customer-visit/visit-activity/avr', subComponent: AVR, exact: true },
            { urlRoute: '/customer-visit/call-history', subComponent: CallHistory, exact: true },
            { urlRoute: '/Telesales-summary/:id', subComponent: TelesalesSummary },
            
        ]
    },
    {
        roleAccess: MemberMenuUrl.PRODUCT_LIST,
        listRoute: [
            { urlRoute: '/product-list', subComponent: Product }
        ]
    },
    {
        roleAccess: MemberMenuUrl.CUSTOMER_MANAGEMENT,
        listRoute: [
            { urlRoute: '/customer-management', subComponent: Customer, exact: true },
            { urlRoute: '/customer-management-update/:id', subComponent: CustomerManagementUpdate },
            { urlRoute: '/customer-management/customer-management', subComponent: CustomerManagement },
            { urlRoute: '/customer-management/order-tracking', subComponent: OrderTracking },
            { urlRoute: '/customer-management/order-tracking-outlet/:id', subComponent: OrderTrackingOutlet },

            { urlRoute: '/customer-visit/visit-activity/order-taking', subComponent: CustomerVisitOrderTaking, exact: true },
            { urlRoute: '/ar-data', subComponent: ARData, exact: true },
            { urlRoute: '/eContract', subComponent: Econtract, exact: true },
            { urlRoute: '/TenderQuota', subComponent: TenderQuota, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.REPORTS,
        listRoute: [
            { urlRoute: '/reports', subComponent: ReportMenu, exact: true },
            { urlRoute: '/reports/monthly-sales-report', subComponent: MonthlySalesReport },
            { urlRoute: '/reports/call-report', subComponent: VisitReport, exact: true },

            { urlRoute: '/reports/call-report/call-activities/images/:id', subComponent: CallByReportImages },
            { urlRoute: '/reports/call-report/:tab', subComponent: VisitReportCallActivities },

            { urlRoute: '/reports/sales-trend-report/:category', subComponent: SalesReportPrincipal, exact: true },
            { urlRoute: '/reports/monthly-collection-report', subComponent: MonthlyCollectionReport, exact: true },
            { urlRoute: '/reports/collection-trend-report', subComponent: CollectionReportOutlet },
            { urlRoute: '/reports/visit-compliance-report', subComponent: VisitComplianceReport, exact: true },
            { urlRoute: '/reports/visit-compliance-report/detail', subComponent: VisitComplianceReportDetail },
            { urlRoute: '/reports/promo-report', subComponent: PromoReport, exact: true },
            { urlRoute: '/reports/promo-report/detail', subComponent: PromoReportDetail },
            { urlRoute: '/reports/incentive-report', subComponent: IncentiveReport },

            { urlRoute: '/reports/monthly-collection-report/outlet/invoice-list/:slug', subComponent: InvoiceListByOutlet },
            { urlRoute: '/reports/avr/:category', subComponent: AVRReport },

            { urlRoute: '/reports/flm/:tab', subComponent: FLMReport },
            { urlRoute: '/reports/slm/:tab', subComponent: SLMReport },
            { urlRoute: '/reports/application-sync-report', subComponent: ApplicationSyncReport },
            { urlRoute: '/reports/call-plan-report/:tab', subComponent: CallPlanReport },
            { urlRoute: '/reports/location-mapped-report', subComponent: LocationMappedReport },
            { urlRoute: '/reports/utility-report/:tab', subComponent: UtilityReport },
            { urlRoute: '/reports/tele-seller-report/:tab', subComponent: TeleSellerReport },
            { urlRoute: '/reports/consignment-report/:tab', subComponent: ConsignmentReport },
            { urlRoute: '/reports/customer-report/:tab', subComponent: CustomerReport },
            { urlRoute: '/coaching-module/coaching-acknowledgement/coaching-form', subComponent: FLMCoachingForm, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.VISIT_PLAN_TEAM,
        listRoute: [
            { urlRoute: '/visit-plan-team', subComponent: VisitPlanTeam, exact: true },
            { urlRoute: '/visit-plan-team/rejected-detail/:id', subComponent: VisitPlanTeamRejectedDetail },
            { urlRoute: '/visit-plan-team/approved-detail/:id', subComponent: VisitPlanTeamApprovedDetail },
            { urlRoute: '/visit-plan-team/request-detail/:id', subComponent: VisitPlanTeamRequestDetail },
        ]
    },
    {
        roleAccess: MemberMenuUrl.ACCOUNT,
        listRoute: [
            { urlRoute: '/account/change-password', subComponent: AccountChangePassword },
            { urlRoute: '/account/reminder', subComponent: AccountReminder },
            { urlRoute: '/account/change', subComponent: ChangeField },
            { urlRoute: '/account', subComponent: AccountProfile, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.SALESMAN_ACTIVITY,
        listRoute: [
            { urlRoute: '/salesman-activity', subComponent: SalesmanActivity, exact: true },
            { urlRoute: '/salesman-activity/territory-management', subComponent: TerritoryManagement, exact: true },
            { urlRoute: '/salesman-activity/territory-management/create', subComponent: TerritoryManagementCreate },
            { urlRoute: '/salesman-activity/territory-management/detail/:id', subComponent: TerritoryManagementUpdate },
            { urlRoute: '/salesman-activity/salesman-tracking-report', subComponent: SalesmanTrackingReport },
        ]
    },
    {
        roleAccess: MemberMenuUrl.COLLECTION_COLLECTED,
        listRoute: [
            { urlRoute: '/collection-collected', subComponent: CollectionCollected, exact: true },
            { urlRoute: '/collection-collected/history-list/:id', subComponent: CollectionCollectedHistory },
            { urlRoute: '/collection-collected/invoice-list', subComponent: CollectionCollectedInvoiceList },
            { urlRoute: '/collection-collected/aditional-cost', subComponent: CollectionCollectedAditionalCost },
            { urlRoute: '/collection-collected/aditional-list/:id', subComponent: CollectionCollectedAditionalList },
        ]
    },
    {
        roleAccess: MemberMenuUrl.TARGET_MANAGEMENT,
        listRoute: [
            { urlRoute: '/target-management', subComponent: TargetManagement, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.CALL_PLANNING,
        listRoute: [
            { urlRoute: '/call-planning', subComponent: CallPlanning, exact: true },
            { urlRoute: '/call-planning/detail/:mode/:memberNik/:currentDate/:from', subComponent: CallPlanningPlanVisit, exact: true },
            { urlRoute: '/call-planning/detail/:mode/:memberNik/:currentDate', subComponent: CallPlanningPlanVisit, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.CALL_PLANNING_REQUEST,
        listRoute: [
            { urlRoute: '/call-planning/manager', subComponent: CallPlanningApprove, exact: true },
            { urlRoute: '/call-planning/:mode/:memberNik/:SalesTypeCode/:id/:fromDate/:toDate', subComponent: CallPlanning, exact: true },
            { urlRoute: '/call-planning/detail/:mode/:memberNik/:currentDate', subComponent: CallPlanningPlanVisit, exact: true },
            { urlRoute: '/call-planning/detail/:mode/:memberNik/:currentDate/:from', subComponent: CallPlanningPlanVisit, exact: true },

        ]
    },
    {
        roleAccess: MemberMenuUrl.CALL_PLANNING_MODULE,
        listRoute: [

            { urlRoute: '/call-planning/manager', subComponent: CallPlanningApprove, exact: true },
            { urlRoute: '/call-planning-module', subComponent: CallPlanningMenu, exact: true },
            { urlRoute: '/call-planning/coaching', subComponent: CallPlanningCoaching, exact: true },
            { urlRoute: '/call-planning/:mode/:memberNik/:id', subComponent: CallPlanning, exact: true },
            { urlRoute: '/call-planning/:mode/:memberNik/:SalesTypeCode/:id/:fromDate/:toDate', subComponent: CallPlanning, exact: true },
            { urlRoute: '/call-planning/detail/:mode/:memberNik/:currentDate', subComponent: CallPlanningPlanVisit, exact: true },
            { urlRoute: '/call-planning/detail/:mode/:memberNik/:currentDate/:from', subComponent: CallPlanningPlanVisit, exact: true },
            { urlRoute: '/coaching-module/planned/:mode/:flmNik/:month/:year', subComponent: FLMPlanTimeSheet, exact: true },
            { urlRoute: '/coaching-module/planned/:mode/:flmNik/:currentDate', subComponent: FLMPlanTimeSheetDetail, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.AVR,
        listRoute: [
            { urlRoute: '/customer-visit/visit-activity/avr', subComponent: AVR, exact: true },
            { urlRoute: '/customer-visit/visit-activity/:category', subComponent: AVR },
        ]
    },
    {
        roleAccess: MemberMenuUrl.DOWNLOAD,
        listRoute: [
            { urlRoute: '/download', subComponent: Download },
        ]
    },
    {
        roleAccess: MemberMenuUrl.COACHING_MODULE,
        listRoute: [
            { urlRoute: '/coaching-module', subComponent: FLMMenu, exact: true },
            { urlRoute: '/coaching-module/unplanned', subComponent: FLMActualTimeSheet, exact: true },
            { urlRoute: '/coaching-module/unplanned/calender', subComponent: FLMActualTimeSheetCalender, exact: true },
            { urlRoute: '/coaching-module/planned', subComponent: FLMPlanTimeSheet, exact: true },
            { urlRoute: '/coaching-module/planned/:mode/:flmNik/:month/:year', subComponent: FLMPlanTimeSheet, exact: true },
            { urlRoute: '/coaching-module/planned/:mode/:flmNik/:currentDate', subComponent: FLMPlanTimeSheetDetail, exact: true },
            { urlRoute: '/coaching-module/planned/:currentDate', subComponent: FLMPlanTimeSheetDetail, exact: true },
            { urlRoute: '/coaching-module/planned/:currentDate/:from', subComponent: FLMPlanTimeSheetDetail, exact: true },
            { urlRoute: '/coaching-module/coaching-acknowledgement', subComponent: FLMAcknowledgement, exact: true },
            { urlRoute: '/coaching-module/coaching-acknowledgement/coaching-form', subComponent: FLMCoachingForm, exact: true },
            { urlRoute: '/coaching-module/coaching-acknowledgement/slm', subComponent: FLMAcknowledgement, exact: true },
            { urlRoute: '/coaching-form', subComponent: FLMCoachingForm, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.FLM_ACKNOWLEDGMENT,
        listRoute: [
            { urlRoute: '/flm-coaching-acknowledge', subComponent: FLMAcknowledgement, exact: true },
            { urlRoute: '/flm-coaching-form', subComponent: FLMCoachingForm, exact: true },
            { urlRoute: '/coaching-form', subComponent: FLMCoachingForm, exact: true },
            { urlRoute: '/coaching-module/coaching-acknowledgement/coaching-form', subComponent: FLMCoachingForm, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.DASHBOARD,
        listRoute: [
            { urlRoute: '/dashboards', subComponent: DashboardMenu, exact: true },
            { urlRoute: '/dashboards/call-planning', subComponent: CallPlanDashboard, exact: true },
            { urlRoute: '/dashboards/order-recommendation', subComponent: OrderRecommendationDashboard, exact: true },
            { urlRoute: '/dashboards/dashboard-and-productivity-report', subComponent: DashboardandProductivityReport, exact: true },
            { urlRoute: '/dashboards/c-t-dashboard', subComponent: CandTDashboard, exact: true },
            { urlRoute: '/dashboards/view-powerBi-report', subComponent: CallPlanPowerBi, exact: true },
            { urlRoute: '/dashboards/looker-validation', subComponent: LookerValidation, exact: true },
            { urlRoute: '/dashboards/view-looker-report', subComponent: SalesTargetSetting, exact: true },
            { urlRoute: '/dashboards/customer-actual-sales', subComponent: CustomerActualSales, exact: true },
            { urlRoute: '/dashboards/ClassificationTargetingOutput_MainOffice', subComponent: ClassificationTargetingOutput_MainOffice, exact: true },
            { urlRoute: '/dashboards/ClassificationTargetingOutput_Branch', subComponent: ClassificationTargetingOutput_Branch, exact: true }
        ]
    },
    {
        roleAccess: MemberMenuUrl.DOCUMENT_LIBRARY,
        listRoute: [
            { urlRoute: '/documentLibrary', subComponent: DocumentLibrary, exact: true },
            { urlRoute: '/document_library', subComponent: DocumentLibrary, exact: true },
        ]
    },
    {
        roleAccess: MemberMenuUrl.SLMCALENDAR,
        listRoute: [
            { urlRoute: '/slmcalendar', subComponent: SlmCalendarView, exact: true },
            { urlRoute: '/slmDetails', subComponent: SlmDetails, exact: true },
            { urlRoute: '/customer-visit/outlet', subComponent: CustomerVisitOutlet }
        ]
    },

]