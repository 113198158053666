import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textarea: {
        width: '100%',
        backgroundColor: '#EFF0F6',
        borderRadius: '0.375rem',
        padding: '1rem',
        outline: 'none'
    },
}));

const BaseTextarea = (props) => {
    const classes = useStyles();

    return (
        <TextareaAutosize
            className={classes.textarea}
            {...props}
        />
    );
}

export default React.memo(BaseTextarea);