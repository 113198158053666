import React from 'react';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';

const styled = makeStyles((theme) => ({
  btnRoot: {
    outline: 'border none',
    textAlign: 'center',
    transition: 'none',
    '&:focus': {
      outline: 'none'
    },
  },
  colorPrimary: {
    backgroundColor: '#003948',
    color: '#f1f1f1',
    '&:hover': {
      transition: '.5s',
      background: 'linear-gradient(#003948, #001e24)',
      color: '#f1f1f1',
    },
  },
}))

const MemoizedButton = React.memo(Button);
const ButtonArrow = ({ onClick, disabled, children }) => {
  const clx = styled();

  return (
    <MemoizedButton
      variant="contained"
      classes={{
        root: clx.btnRoot,
        contained: clx.colorPrimary
      }}
      onClick={onClick}
      disabled={disabled || false}
    >
      {children}
    </MemoizedButton>
  )
}

export default ButtonArrow