const initialState = {
    Tabs: []
}

export const TenderReducer = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case "GET_TENDER_TABS": {
            return {
                ...state,
                Tabs: payload
            }
        }

        default:
            return state;
    }
}