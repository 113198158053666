export const headerStyle = {
  color: 'white',
  backgroundColor: '#157478',
  padding: 14,
  textTranform: 'capitalize'
}

export const style = {
  padding: '1rem',
  textAlign: 'center',
}

export const productStyle = {
  color: 'white',
  backgroundColor: '#157478',
  padding: 5,
  textTranform: 'capitalize'
}
