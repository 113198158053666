const initialState = {
	customerSurvey: null,
}

export const CustomerSurveyReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case 'CUSTOMER_SURVEY': {
			return {
				...state,
				customerSurvey: payload.data.success ? { ...payload.data.data } : null
			}
		}
		default: return state
	}
}