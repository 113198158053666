const Spinner = ({ loading }) => {
    return (
        <div className={`${loading ? "" : "hidden"}`}>
            <div className="bg-loader overlay">
                <div className="flex flex-col cv-spinner">
                    <span className="spinner"></span>
                </div>
            </div>
        </div>
    )
}

export default Spinner;