import format from "date-fns/format";
import { CallReportConstant, EngageConstant } from "../../constant"
import { callReportActionConstant } from "../constants/actionTypes"

const initialState = {
	reportDetail: {
		fromRow: 0,
		items: [],
		keyword: "",
		lastPage: 0,
		limit: 0,
		page: 0,
		toRow: 0,
		totalRows: 0,
	},
	reportDetailImages: [],
	markets: [],
	teams: [],
	saleRepresentatives: [],
	fieldImpressionData: []
}

export const CallReportReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case callReportActionConstant.CALL_REPORT_DATA: {
			const createData = (
				date,
				team,
				businessType,
				salesRepresentative,
				territoryCode,
				status,
				missedCallRemark, // // new
				plannedUnplanned, // // new
				activity,
				otherworkName,
				otherworkNote,
				soldToCode,
				soldToName,
				customer,
				customerCode,
				customerClass,
				shipTo, // new
				prinicipal,
				product,
				productCode,
				stockInventoryQuantity,
				listed,
				shelfStock,
				outofStock,
				location,
				shelfSpace,
				shareofSpace,
				noofFacings,
				ofPhotosUpload,
				productEnlistment,
				promotionalActivity,
				salesRecommendtaionfromICO,
				personinCharge,
				withContract,
				startDate,
				endDate,
				presentationDurationTime,
				presentationFileName,
				performCollection,
				modeofCollection,
				placedOrder,
				modeofOrder,
				reasonofNotusingezrx,
				checkin,
				checkout,
				visitDuration,
				travelDurationTime,
				actualPhotos,
				preCallNotes,
				postCallNotes,
				barrierEncountered,
				callSource,
				modalityofCall,
				routeStatus,
				rayonCode
			) => (
				{
					date,
					team,
					businessType,
					salesRepresentative,
					territoryCode,
					status,
					missedCallRemark, // new
					plannedUnplanned, // new
					activity,
					otherworkName,
					otherworkNote,
					soldToCode,
					soldToName,
					customer,
					customerCode,
					customerClass,
					shipTo, // new
					prinicipal,
					product,
					productCode,
					stockInventoryQuantity,
					listed,
					shelfStock,
					outofStock,
					location,
					shelfSpace,
					shareofSpace,
					noofFacings,
					ofPhotosUpload,
					productEnlistment,
					promotionalActivity,
					salesRecommendtaionfromICO,
					personinCharge,
					withContract,
					startDate,
					endDate,
					presentationDurationTime,
					presentationFileName,
					performCollection,
					modeofCollection,
					placedOrder,
					modeofOrder,
					reasonofNotusingezrx,
					checkin,
					checkout,
					visitDuration,
					travelDurationTime,
					actualPhotos,
					preCallNotes,
					postCallNotes,
					barrierEncountered,
					callSource,
					modalityofCall,
					routeStatus,
					rayonCode
				});

			const getName = (arr = [], id) => (id && arr.find(e => e.id === id).name)
			const formatTime = (time) => (time && format(new Date(time), 'HH:mm:ss'))
			const formatStringTime = (timeStr) => timeStr.split('.')[0]
			const mapData = (data = []) => {
				const result = data.reduce((previousValue, currentValue) => {
					let arr = []
					if (currentValue.products.length > 0) {
						currentValue.products.forEach((e) => {
							arr.push({
								...currentValue,
								...e
							})
						})
					} else {
						arr.push(currentValue);
					}

					return [...previousValue, ...arr];
				}, []).map(o => {

					return createData(
						o.date,	      //	date,
						o.team_name,	//	  team,
						o.business_type,	//	  businessType,
						// o.member_nik,	//	  salesCode,
						o.member_fullname,	//	  salesRepresentative,
						o.territory_code,	//	  territoryCode,
						o.status,	//	  status,
						o.missed_call_remark, // new missedCallRemark
						o.is_planed, // new plannedUnplanned
						getName(EngageConstant.ACTIVITY, o.activity),	//	  activity,
						o.otherwork_name,	//	  otherworkName,
						o.otherwork_note,	//	  otherworkNote,
						o.outlet_sold_to.outlet_code,	//	  soldToCode,
						o.outlet_sold_to.outlet_name,	//	  soldToName,
						o.outlet_name,	//	  customer,
						o.outlet_code,	//	  customerCode,
						o.outlet_class,	//	  customerClass,
						'', // shipTo
						o.principal,	//	  prinicipal,
						o.product_name,	//	  product,
						o.Product_code,	//	  productCode,
						o.stock_inventory_quantity,	//	  stockInventoryQuantity,
						o.listed,	//	  listed,
						o.shelf_stock,	//	  shelfStock,
						o.out_of_stock,	//	  outofStock,
						o.location,	//	  location,
						o.shelf_space,	//	  shelfSpace,
						o.share_of_space,	//	  shareofSpace,
						o.no_of_product_facings,	//	  noofFacings,
						o.visit_id,	//	  ofPhotosUpload,
						o.product_enlistment,	//	  productEnlistment,
						o.promotional_activity,	//	  promotionalActivity,
						o.sales_recommendtaion_from_ico,	//	  salesRecommendtaionfromICO,
						o.person_in_charge,	//	  personinCharge,
						o.with_contract,	//	  withContract,
						o.start_date,	//	  startDate,
						o.end_date,	//	  endDate,
						o.presentation_duration_time,	//	  presentationDurationTime,
						o.presentation_file_name?.join(', '),	//	  presentationFileName,
						o.perform_collection,	//	  performCollection,
						o.mode_of_collection,	//	  modeofCollection,
						o.placed_order,	//	  placedOrder,
						o.mode_of_order,	//	  modeofOrder,
						o.reason_non_app,	//	  reasonofNotusingezrx,
						formatTime(o.checkin_time),	//	  checkin,
						formatTime(o.checkout_time),	//	  checkout,
						formatStringTime(o.duration),	//	  visitDuration,
						formatStringTime(o.travel_duration),	//	  travelDurationTime,
						o.photo_upload,	//	  actualPhotos,
						o.pre_call_notes,	//	  preCallNotes,
						o.post_call_notes,	//	  postCallNotes,
						o.barrier_encountered,	//	  barrierEncountered,
						o.call_source,	//	  callSource,
						o.modality_of_call,	//	  modalityofCall,
						o.route_status_raw,	//	  routeStatus,
						o.rayon_code
					)
				})

				return result;
			}

			let result = [];
			const map = payload.data.success ? mapData(payload.data.data.items)
				.map(e => Object.keys(e).reduce((result, key) => ({ ...result, [key]: (e[key] || '') }), {})) : []
			if (payload.type === CallReportConstant.ACTION.ADD) {
				result = state.reportDetail.items.concat(map);
			} else {
				result = map;
			}
			return {
				...state,
				reportDetail: {
					...payload.data.data,
					items: result,
					loading: false
				}
			}
		}

		case callReportActionConstant.CALL_IMAGES_DATA: {
			return {
				...state,
				reportDetailImages: payload.data.success
					? payload.data.data
						.map((element) => {
							return element.split(',')
						}).flat()
					: []
			}
		}

		case callReportActionConstant.CALL_REPORT_MARKET_DATA: {
			return {
				...state,
				markets: payload.data.success ? payload.data.data.map(e => {
					return {
						id: e.saleOrg_code,
						name: e.saleOrg_name
					}
				}) : []
			}
		}

		case callReportActionConstant.CALL_REPORT_TEAM_DATA: {
			return {
				...state,
				teams: payload.data.success ? payload.data.data.items.map(e => {
					return {
						id: e.team_code,
						name: e.team_name
					}
				}) : []
			}
		}

		case callReportActionConstant.CALL_REPORT_SALEMAN_DATA: {
			return {
				...state,
				salemans: payload.data.success ? payload.data.data.items.map(e => {
					return {
						id: e.member_nik,
						name: e.member_username
					}
				}) : []
			}
		}

		case 'GET_FIELD_IMPRESSION_DATA': {
            return {
                ...state,
                fieldImpressionData: payload
            }
        }
		default: return state
	}
}