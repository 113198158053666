const initialState = {
  callStatus: [],
  communicationChannel: [],
  MissedReasonRemarks: [],
  adoptionLevel: [],
  priority: [],
  callClassification: [],
  tableList: [],
  callType: [],
  optionList: [],
  callstatus: []
};

export const TeleSalesReducer = (
  state = initialState,
  { type, payload = {} }
) => {
  switch (type) {
    case "GET_TELESALES_LIST": {
      const getCallStatus = payload.filter(
        (item) => item.type === "Call status"
      );
      const getCommunicationChannel = payload.filter(
        (item) => item.type === "Communication channel"
      );
      const getAdoptionLevel = payload.filter(
        (item) => item.type === "Adoption level"
      );
      const getPriority = payload.filter((item) => item.type === "Priority");
      const getCallClassification = payload.filter(
        (item) => item.type === "Call classification"
      );
      const getCallType = payload.filter(
        (item) => item.type === "Call Type"
      );
      const getMisssedResonRemarks = payload.filter(
        (item) => item.type === "Misssed ResonRemarks"
      );


      return {
        ...state,
        callStatus: getCallStatus,
        communicationChannel: getCommunicationChannel,
        MissedReasonRemarks: getMisssedResonRemarks,
        adoptionLevel: getAdoptionLevel,
        priority: getPriority,
        callClassification: getCallClassification,
        CallType: getCallType
      };
    }
    case "GET_TELESALES_TABLE_LIST": {
      return {
        ...state,
        tableList: payload,
      };
    }
    case "GET_OPTION_DATA": {
      const getStock = payload.filter((item) => item.type === "Stock");
      return {
        ...state,
        optionList: getStock,
      };
    }
    case "GET_ANSUNANS_DATA": {
      return {
        ...state,
        callstatus: payload,
      };
    }
    default:
      return state;
  }
};
