import React, { useState } from 'react'
import close from '../../../assets/icons/close.svg'
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import IC from 'leaflet/dist/images/marker-icon-2x.png'
// import IS from 'leaflet/dist/images/marker-shadow.png'

var icon = L.icon({
  iconUrl: IC,
  iconSize: [25, 35],
  iconAnchor: [22, 35],
  // shadowUrl: IS,
  popupAnchor: [-10, -35],
  // shadowSize: [25, 50],
  // shadowAnchor: [22, 35]
})

const PopupDistance = ({ data, pin }) => {
  const [popup, setpopup] = useState(false)
  const modalShown = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  const handlePopup = () => {
    setpopup(!popup)
    modalShown()
  }

  setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 100);

  const center = [data.outletLatitude, data.outletLongitude]
  const center2 = [data.actualLatitude, data.actualLongitude]

  const polyline = [
    [data.outletLatitude, data.outletLongitude],
    [data.actualLatitude, data.actualLongitude],
  ]

  const limeOptions = { color: 'lime' }

  return (
    <>
      <span className="cursor-pointer" onClick={handlePopup}><img alt="pin" src={pin} className="flex justify-center" /></span>
      <div className={`overflow-auto fixed w-full h-full flex flex-wrap items-center justify-center top-0 left-0 py-10 ${!popup ? "hidden" : ""}`} style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 999999 }}>
        <div className="w-8/12 p-4 bg-white rounded">
          <div className="flex flex-wrap items-center justify-end">
            <div className="w-4 py-1 text-sm text-center text-white rounded-full" onClick={() => setpopup(!popup)}>
              <img src={close} alt="close" className="cursor-pointer" />
            </div>
          </div>
          <div className="flex flex-wrap max-w-screen-xl py-2 mx-auto gap-y-10 md:p-4">
            <div className="flex flex-col w-full pl-5">
              <div className="text-lg text-left">Distance</div>
              <div className="pb-4 text-left">From [{data.outletLatitude}, {data.outletLongitude}] To [{data.actualLatitude}, {data.actualLongitude}] </div>
              <div className="w-full h-auto mt-5 md:mt-0">
                <MapContainer center={center} zoom={25} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Polyline center={center} pathOptions={limeOptions} positions={polyline}
                  >
                    <Marker position={center} icon={icon}>
                      <Popup>
                        Master.
                      </Popup>
                    </Marker>
                    <Marker position={center2} icon={icon}>
                      <Popup>
                        Visit.
                      </Popup>
                    </Marker>
                  </Polyline>
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PopupDistance
