const initialState = {
    dihNo: [],
    listDeposit: null,
    listDepositById: null
  }
  
  export const InvoiceListByDih = (state = initialState, { type, payload = {} })  => {
    switch (type) {
      case 'DIH_NO': {
        return {
          ...state,
          dihNo: payload.data
        }
      }
      case 'GET_LIST_DEPOSIT': {
        return {
          ...state,
          listDeposit: payload
        }
      }
      case 'ADD_NEW_COSTS': {

        return {
          ...state
        }
      }
      default: return state
    }
  }