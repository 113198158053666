import { customerVisitPlanActionTypes } from "../constants/actionTypes"

const initialState = {
	visitPlan: null,
	visitProgress: null,
	visitPlanMonth: null
}

export const CustomerVisitPlanReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case customerVisitPlanActionTypes.CUSTOMER_VISIT_PLAN: {
			return {
				...state,
				visitPlan: payload.data.success ? { ...payload.data } : null
			}
		}
		case customerVisitPlanActionTypes.CUSTOMER_VISIT_PROGRESS: {
			return {
				...state,
				visitProgress: payload.data.success ? { ...payload.data } : null
			}
		}
		case customerVisitPlanActionTypes.CUSTOMER_VISIT_PLAN_MONTH: {
			return {
				...state,
				visitPlanMonth: payload.data.success ? { ...payload.data.data } : null
			}
		}
		default: return state
	}
}