const initialState = {
	publicHoliday: [],
	weeklyOffs: [],
	holidays: [],
	visitPlanDetail: null,
	lastSummaryByOutlet: null,
	monthlyTarget: null,
	weeklyOffArr: []
}

export const VisitPlanReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case 'PUBLIC_HOLIDAY': {
			return {
				...state,
				publicHoliday: payload.data.success ? payload.data.data?.items : payload.success ? payload?.data?.items : [],
				holidays: payload.data.success ?
					payload.data.data?.items?.filter(e => e.publicHolidayId)
						.map(e => new Date(e.date))
					: payload?.success ? payload?.data?.items?.filter(e => e.publicHolidayId)
						.map(e => new Date(e.date))
						: [],
			}
		}
		case 'WEEKLY_OFF': {

			return {
				...state,
				weeklyOffs: payload.data.success ?
					payload.data.data.map(e => new Date(e.date))
					: payload?.success ? payload?.data.map(e => new Date(e.date))
						: [],

				weeklyOffArr: payload.data.success ?
					payload.data.data
					: payload?.success ? payload?.data
						: []
			}
		}
		case 'VISIT_PLAN_BY_ID': {
			return {
				...state,
				visitPlanDetail: { ...payload.data.data }
			}
		}
		case 'UPDATE_VISIT_PLAN': {
			return {
			  ...state,
			  visitPlanDetail: { ...payload },
			};
		}
		case 'GET_PER_MONTH_TARGET': {
			return {
				...state,
				monthlyTarget: payload
			}
		}
		default: return state
	}
}