const initialState = {
    documentList: []
}

export const DocumentReducer = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case 'GET_DOCUMENT_LIST': {
            return {
                ...state,
                documentList: payload
            }
        }

        default: return state
    }
}