// import React, { lazy, Suspense } from 'react';
// import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { routesAuthen } from './router/routeConstant';
// import Spinner from './components/spinner/spinner';
// import { RequireAuth } from './components/auth/RequireAuth';
// import PowerBIForCallPlan from './components/powerBI/PowerBIForCallPlan';

// const LoginSSO = lazy(() => import('./components/auth/LoginSSO'));
// const LoginCallback = lazy(() => import('./components/auth/LoginCallback'));
// const Dashboard = lazy(() => import('./pages/Dashboard'));
// const DashboardPerformanceTrend = lazy(() => import('./pages/DashboardPerformanceTrend'));
// const ChangePassword = lazy(() => import('./pages/ChangePassword'));
// const PageNotFound = lazy(() => import('./pages/PageNotFound'));
// const PowerPI = lazy(() => import('./components/powerBI/powerBI'));

// const SSO_URL = `${process.env.REACT_APP_BASE_URL_SSO}/api/v1/ssologin`;

// const getRouteByPath = (url) => {
//   let tempRoute = [];
//   const initRoutes = routesAuthen;
//   for (let i = 0; i < initRoutes.length; i++) {
//     const element = initRoutes[i];
//     if (element.roleAccess.includes(url)) {
//       tempRoute = [...tempRoute, ...element.listRoute];
//     }
//   }
//   return tempRoute;
// }

// const App = () => {
//   const user = useSelector(state => state.auth.user);
//   const masterMenus = useSelector(state => state.memberPriviledgeMenu.priviledges);

//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const reportId = searchParams.get('reportId');
//   const workspaceId = searchParams.get('workspaceId');
//   const headingName = searchParams.get('headingName');

//   return (
//     <Router basename={process.env.PUBLIC_URL}>
//       <Suspense fallback={<Spinner loading={true} />}>
//         <Switch>
//           <Route path="/redirect">
//             <LoginSSO url={SSO_URL} />
//           </Route>

//           <Route path="/signin-callback" component={LoginCallback} />
//           <Route path='/dashboard/performance-trend' component={DashboardPerformanceTrend} />
//           <Route path='/change-password' component={ChangePassword} />

//           <RequireAuth exact path='(/||/dashboard)' component={Dashboard} />
//           <RequireAuth exact path='/core-kpi-report' component={PowerPI} />
//           <RequireAuth exact path='/view-powerBi-report' component={(props) => <PowerBIForCallPlan {...props} reportId={reportId} workspaceId={workspaceId} />} />

//           {user && masterMenus?.masterMenus?.map(path => {
//             return getRouteByPath(path.memberMenuUrl).map(route => {
//               return <RequireAuth key={route.urlRoute} path={route.urlRoute} component={route.subComponent} exact={route.exact} />
//             })
//           })}

//           <Route path='*' component={PageNotFound} />
//         </Switch>
//       </Suspense>
//     </Router>
//   )
// }

// export default App;



import { lazy, Suspense, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { routesAuthen } from './router/routeConstant';
import Spinner from './components/spinner/spinner';
import { RequireAuth } from './components/auth/RequireAuth';
import PowerBIForCallPlan from './components/powerBI/PowerBIForCallPlan';
// import TenderManagement from './pages/TenderManagement';
// import SlmCalendarView from './pages/SLMCalendar/SlmCalendarView';
// import SlmDetails from './pages/SLMCalendar/SlmCalendarView/SlmDetails/SlmDetails';

const LoginSSO = lazy(() => import('./components/auth/LoginSSO'));
const LoginCallback = lazy(() => import('./components/auth/LoginCallback'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const DashboardPerformanceTrend = lazy(() => import('./pages/DashboardPerformanceTrend'));
const ChangePassword            = lazy(() => import('./pages/ChangePassword'));
const PageNotFound              = lazy(() => import('./pages/PageNotFound'));
const PowerPI                   = lazy(() => import('./components/powerBI/powerBI'))

const SSO_URL = `${process.env.REACT_APP_BASE_URL_SSO}/api/v1/ssologin`;

const getRouteByPath = (url) => {
  let tempRoute = [];
  const initRoutes = routesAuthen;
  for (let i = 0; i < initRoutes.length; i++) {
    const element = initRoutes[i];
    if (element.roleAccess.includes(url)) {
      tempRoute = [...tempRoute, ...element.listRoute];
    }
  }
  return tempRoute;
}

const App = () => {
  const user = useSelector(state => state.auth.user);
  const masterMenus = useSelector(state => state.memberPriviledgeMenu.priviledges);
  const mstc = useSelector(state => state.auth.mstc);

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reportId = searchParams.get('reportId');
  const workspaceId = searchParams.get('workspaceId');
  const headingName = searchParams.get('headingName');
  const mstcTC = searchParams.get('mstc'); 
  const extraRouteDetailsForTcFLM = {
      "createdAt": "2021-04-05T13:29:24.662Z",
      "createdBy": "",
      "deletedAt": null,
      "isActive": 1,
      "memberMenuAlias": "customer-visit",
      "memberMenuIcon": "icon-visit",
      "memberMenuId": "6b28482d-8f7a-43d0-83a4-8c1544e9f995",
      "memberMenuName": "Customer Engagement",
      "memberMenuPlatform": "web",
      "memberMenuType": 1,
      "memberMenuUrl": "customer-visit",
      "memberPriviledge": {
          "createdAt": "2022-12-27T16:40:02.0844492+07:00",
          "createdBy": "",
          "deletedAt": null,
          "menuAdd": 1,
          "menuDelete": 1,
          "menuEdit": 1,
          "menuOther": 1,
          "menuView": 1,
          "priviledgeId": "5642243c-f7cd-45fe-9d94-96f0cd88ed95",
          "priviledgeMenuId": "6b28482d-8f7a-43d0-83a4-8c1544e9f995",
          "priviledgeRoleId": "23980072-0c72-4a23-a8df-20ca374e9b75",
          "updatedAt": "2022-12-27T16:40:02.0844492+07:00",
          "updatedBy": ""
      },
      "sequence": 1,
      "updatedAt": "2024-06-12T05:40:00.7304829Z",
      "updatedBy": ""
  }

  useEffect(() => {
    if (mstcTC !== '' && mstcTC !== null && mstcTC !== undefined) {
      dispatch({
        type: 'FLM_TC_MSTC',
        payload: mstcTC
      });
    }
    
  }, [mstcTC])

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Suspense fallback={<Spinner loading={true} />}>
        <Switch>
          <Route path="/redirect">
            <LoginSSO url={SSO_URL} />
          </Route>

          <Route path="/signin-callback" component={LoginCallback} />
          <Route path='/dashboard/performance-trend' component={DashboardPerformanceTrend} />
          <Route path='/change-password' component={ChangePassword} />
           {/* <Route path='/tenderManagement' component={TenderManagement} /> */}
          {/* <Route path='/slmcalendar' component={SlmCalendarView} />
          <Route path='/slmDetails' component={SlmDetails} /> */}

            <RequireAuth exact path='(/||/dashboard)' component={Dashboard} />
            <RequireAuth exact path='/core-kpi-report' component={PowerPI} />
            <RequireAuth exact path='/view-powerBi-report' component={(props) => <PowerBIForCallPlan {...props} reportId={reportId} workspaceId={workspaceId} />} />
            {user && masterMenus?.masterMenus?.map(path => {
              // if(path.memberMenuUrl === 'customer-visit') {
                
              // }
              // else{
                return getRouteByPath(path.memberMenuUrl).map(route => {
                  return <RequireAuth key={route.urlRoute} path={route.urlRoute} component={route.subComponent} exact={route.exact} />
                })
              // }
            })}
            {user && mstc !== '' && mstc !== null && mstc !== undefined && (
               console.log('mstc', mstc),
               getRouteByPath(extraRouteDetailsForTcFLM.memberMenuUrl).map(route => {
                return <RequireAuth key={route.urlRoute} path={route.urlRoute} component={route.subComponent} exact={route.exact} />
              }))
            }




            <Route path='*' component={PageNotFound} />
          </Switch>
        </Suspense>
    </Router>
  )
}

export default App
