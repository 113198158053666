import React from 'react'
import { BaseSearch } from '.';

const InputSearch = (props) => {
  const {
    id,
    name,
    value,
    onChange
  } = props;
  return (
    <div className="flex items-center gap-x-5 w-full">
      <label>Search:</label>
      <BaseSearch
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default React.memo(InputSearch)
