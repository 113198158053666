import { useState } from 'react'
import close from '../../../assets/icons/close.svg'
import { toCurrency } from '../../../utils/common'

const PopupDetailProduct = (props) => {
  const [popup, setpopup] = useState(false)

  const handlePopup = () => setpopup(!popup)

  return (
    <>
      <span className="cursor-pointer text-secondary hover:underline" onClick={handlePopup}>
        {props.type === 'suggestion' ? (
          props?.original?.productName === '' ? 'Product Name' : props?.original?.productName
        ) : (
          props?.original?.product?.productName === '' ? 'Product Name' : props?.original?.product?.productName
        )}
      </span>

      {popup && (
        <div className={` z-40 fixed w-full h-full flex flex-wrap items-center justify-center top-0 left-0 py-10`} style={{ backgroundColor: "rgba(0,0,0,0.5)", fontSize: 16 }}>

          <div className="w-10/12 p-6 bg-white flex-wrap rounded">
            <div className="flex flex-wrap items-center justify-end ">
              <div className="w-4 py-1 text-sm text-center text-white rounded-full" onClick={handlePopup}>
                <img src={close} alt="close" className="cursor-pointer" />
              </div>
            </div>

            <div className="max-w-screen-xl flex-wrap py-2 mx-auto md:p-4">
              <div className="flex flex-col bg-white rounded lg:flex-row flex-wrap">
                <div className="flex flex-wrap flex-col md:flex-row lg:w-7/12">
                  <div className="flex md:w-1/2">
                    <div className="flex flex-col bg-gray-200 w-full gap-y-2 overflow-y-auto" style={{ maxHeight: 500 }}>
                      {props.original.product.ProductImage?.length > 0 && (
                        props.original.product.ProductImage.map(item => (
                          <img className="rounded" key={item.productImageId} src={item.image} alt="" />
                        ))
                      )}
                    </div>
                  </div>
                  <div className="mt-5 text-left md:ml-5 md:mt-0">
                    <div className="block font-bold">
                      {props.type === 'suggestion' ? (
                        props.original.productName === '' ? 'Product Name' : props.original.productName
                      ) : (
                        props.original.product.productName === '' ? 'Product Name' : props.original.product.productName
                      )}
                    </div>
                    <div>
                      {props.type === 'suggestion' ? (
                        `Rp ${toCurrency(parseInt(props.original.hnaPrice))}`
                      ) : (
                        `Rp ${toCurrency(parseInt(props.original.product.hnaPrice))}`
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-10 flex-wrap text-left gap-y-5 lg:w-5/12 lg:mt-0">
                  <div className="flex flex-col flex-wrap">
                    <div className="font-bold">Composition</div>
                    <div className="p-2">
                      {props.type === 'suggestion' ? (
                        props.original.productComposition === '' ? 'Composition' : props.original.productComposition
                      ) : (
                        props.original.product.productComposition === '' ? 'Composition' : props.original.product.productComposition
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col flex-wrap">
                    <div className="font-bold">Benefit</div>
                    <div className="p-2">
                      {props.type === 'suggestion' ? (
                        props.original.productBenefit === '' ? 'Benefit' : props.original.productBenefit
                      ) : (
                        props.original.product.productBenefit === '' ? 'Benefit' : props.original.product.productBenefit
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mt-10 flex-wrap">
                    <div className="font-bold">How To Use</div>
                    <div className="p-2">
                      {props.type === 'suggestion' ? (
                        props.original.productHowToUse === '' ? 'How To Use' : props.original.productHowToUse
                      ) : (
                        props.original.product.productHowToUse === '' ? 'How To Use' : props.original.product.productHowToUse
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PopupDetailProduct
