import React from 'react'
import { headerStyle, style } from './styles'
import { formatDate } from './common'
import './styles/style.css'
import {  SCREEN_TYPE } from "../constant";
import { ButtonCustom } from '../components/button'


const useFieldRowTwo = (props) => {
    const {
        checkboxProps,
        selectedData,
        handleSelectedAllRows,
        handleSelectRow,
        handleClickCallBack, setSelectedFlmNiks,
        setSelectedTeamIds,
        selectedFlmNiks,
        selectedTeamIds,
    } = props

    return {
        avrReportAvailabilityDetail: [
            {
                Header: 'Date',
                accessor: 'date',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Salesman Code',
                accessor: 'salesmanCode',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Salesman Name',
                accessor: 'salesmanName',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Branch',
                accessor: 'branch',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Code',
                accessor: 'outletSoldToCode',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Name',
                accessor: 'outletSoldToName',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Ship to Code',
                accessor: 'customerId',
                headerStyle,
                style,
                minWidth: 300,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Customer Ship to Name',
                accessor: 'customerName',
                headerStyle,
                style,
                minWidth: 300,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Customer Type',
                accessor: 'customerType',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Class',
                accessor: 'customerClass',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Check In',
                accessor: 'checkIn',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Check Out',
                accessor: 'checkOut',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                minWidth: 300,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Product /SKU',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 300,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Stock Inventory Quantity',
                accessor: 'stockInventoryQuantity',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Listed',
                accessor: 'listed',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Shelf Stock',
                accessor: 'shelfStock',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Out of Stock',
                accessor: 'outOfStock',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
        ],
        avrReportAvailablitySummary: [
            {
                Header: 'Date',
                accessor: 'date',
                headerStyle,
                style,
                minWidth: 245,
                maxWidth: 1000,
                sortable: false,
            },
            {
                Header: 'Product /SKU',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 500,
                maxWidth: 1000,
                sortable: false,
            },
            {
                Header: 'Stock Inventory Quantity',
                accessor: 'stockInventoryQuantity',
                headerStyle,
                style,
                minWidth: 500,
                maxWidth: 1000,
                sortable: false,
            },
        ],
        avrReport: [
            {
                Header: 'Date',
                accessor: 'date',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Code',
                accessor: 'outletSoldToCode',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Name',
                accessor: 'outletSoldToName',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Ship to Code',
                accessor: 'customerCode',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },

            {
                Header: 'Customer Ship to Name',
                accessor: 'customer',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Class',
                accessor: 'customerClass',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Product',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Listing',
                accessor: 'listing',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Location',
                accessor: 'location',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Shelf Space',
                accessor: 'layer',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Share of Space',
                accessor: 'shelfStock',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Stock Inventory',
                accessor: 'stockInventory',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'No. of Facings',
                accessor: 'noOfFacing',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Product Enlistment',
                accessor: 'productEnlistment',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Promotional Activity',
                accessor: 'promotionalActivity',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Sales Recommendtaion ICO',
                accessor: 'salesRecommendtaionICO',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            }
        ],
        avrReportVisibilityDetailed: [
            {
                Header: 'Date',
                accessor: 'date',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Salesman Code',
                accessor: 'salesmanCode',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Salesman Name',
                accessor: 'salesmanName',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Branch',
                accessor: 'branch',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Code',
                accessor: 'outletSoldToCode',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Name',
                accessor: 'outletSoldToName',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Ship to Code',
                accessor: 'customerId',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },

            {
                Header: 'Customer Ship to Name',
                accessor: 'customerName',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Type',
                accessor: 'customerType',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Class',
                accessor: 'customerClass',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Check In',
                accessor: 'checkIn',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Check Out',
                accessor: 'checkOut',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },

            {
                Header: 'Product /SKU',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Stock Inventory Quantity',
                accessor: 'stockInventoryQuantity',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Location',
                accessor: 'location',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Share of Space',
                accessor: 'shelfSpace',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Shelf Space',
                accessor: 'shelfSpace',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Product Facings',
                accessor: 'noOfProductFacings',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: '# of Photo Uploads',
                accessor: 'photoUpload',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
                Cell: ({ row }) => {
                    return (
                        row.photoUpload.length > 0
                        && row.photoUpload.map((image, index) => (<a className="block" rel="noreferrer" key={'#_of_photo_uploads_' + index} href={image} target="_blank">{image}</a>))
                    )
                },
            },
            {
                Header: 'Actual Photos',
                accessor: 'photoUpload',
                headerStyle,
                style,
                minWidth: 300,
                maxWidth: 400,
                sortable: false,
                Cell: ({ row }) => {
                    return (
                        row.photoUpload.length > 0 && row.photoUpload.map((image, index) => (<img key={'actual_photo_' + index} src={image} alt="" />))
                    )
                },
            },
        ],
        avrReportVisibilitySummary: [
            {
                Header: 'Month',
                accessor: 'month',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Product /SKU',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Location',
                accessor: 'location',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Location quantity',
                accessor: 'locationQuantity',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Shelf Space',
                accessor: 'layer',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },

            {
                Header: 'Shelf Space Total Qty',
                accessor: 'layerTotalQty',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Total Share of Shelf',
                accessor: 'totalShareOfShelf',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Share of Shelf Qty',
                accessor: 'shareOfShelfQty',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Total Product Facings',
                accessor: 'totalProductFacings',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Product Facings Total Qty',
                accessor: 'productFacingsTotalQty',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
        ],
        avrReportRecommendationDetailed: [
            {
                Header: 'Date',
                accessor: 'date',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Salesman Code',
                accessor: 'salesmanCode',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Salesman Name',
                accessor: 'salesmanName',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Branch',
                accessor: 'branch',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Code',
                accessor: 'outletSoldToCode',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Name',
                accessor: 'outletSoldToName',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Ship to Code',
                accessor: 'customerId',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },

            {
                Header: 'Customer Ship to Name',
                accessor: 'customerName',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Customer Type',
                accessor: 'customerType',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Class',
                accessor: 'customerClass',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Check In',
                accessor: 'checkIn',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Check Out',
                accessor: 'checkOut',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Product Enlistment',
                accessor: 'productEnlistment',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Promotional Activity',
                accessor: 'promotionalActivity',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Personnel in Charge',
                accessor: 'personnelInCharge',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Sales Recommendation',
                accessor: 'salesRecommendation',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
        ],
        avrReportRecommendationSummary: [
            {
                Header: 'Month',
                accessor: 'month',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Product /SKU',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Product Enlistment',
                accessor: 'productEnlistment',
                headerStyle,
                style,
                minWidth: 250,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Promotional Activity',
                accessor: 'promotionalActivity',
                headerStyle,
                style,
                minWidth: 400,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Sales Recommendation based from ICO',
                accessor: 'salesRecommendationICO',
                headerStyle,
                style,
                minWidth: 600,
                maxWidth: 300,
                sortable: false,
            },
        ],
        avrAvailablity: [
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Product/SKUs',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Stock Inventory Quantity',
                accessor: 'stockInventoryQuantity',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Listed',
                accessor: 'listed',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Shelf Stock',
                accessor: 'shelfStock',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Out of Stock',
                accessor: 'outOfStock',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
        ],
        avrVisibility: [
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Product/SKUs',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Stock Inventory Quantity',
                accessor: 'stockInventoryQuantity',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 300,
                sortable: false,
            },
            {
                Header: 'Location',
                accessor: 'location',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Shelf Space',
                accessor: 'layer',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Share of Space',
                accessor: 'shelfSpace',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'No of Product Facings',
                accessor: 'noOfProductFacings',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'With Contract',
                accessor: 'withContract',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Unit of Measurement',
                accessor: 'unitOfMeasurement',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'End Date',
                accessor: 'endDate',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Photo Upload',
                accessor: 'photoUpload',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
                Cell: ({ row }) => {
                    return (
                        row.photoUpload.length > 0 && row.photoUpload.map((image, index) => (<a className="block" rel="noreferrer" key={'uploadData_' + index} href={image} target="_blank">{image}</a>))
                    )
                },
            },
            {
                Header: 'Remarks',
                accessor: 'remarks',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
        ],
        avrRecommendation: [
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Product/SKUs',
                accessor: 'product',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'Type',
                accessor: 'type',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'SubType',
                accessor: 'typeSub',
                headerStyle,
                style,
                minWidth: 200,
                maxWidth: 400,
                sortable: false,
            },
            {
                Header: 'With Contract',
                accessor: 'withContract',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Person in Charge',
                accessor: 'personInCharge',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
            {
                Header: 'End Date',
                accessor: 'endDate',
                headerStyle,
                style,
                minWidth: 150,
                maxWidth: 150,
                sortable: false,
            },
        ],
        callPlanning: [
            {
                Header: () => {
                    return (
                        <input
                            type='checkbox'
                            checked={selectedData.all === 1}
                            indeterminate={selectedData.all === 2 ? "true" : "false"}
                            onChange={() => handleSelectedAllRows()}
                        />
                    )
                },
                accessor: '',
                width: 50,
                sortable: false,
                headerStyle,
                className: '', // belum di isi
                Cell: ({ original }) => {
                    return (

                        <input
                            type='checkbox'
                            checked={selectedData.item[original[checkboxProps.selectorKey]] === true && selectedData.item[original[checkboxProps.selectorKey1]] === true}
                            onChange={() => handleSelectRow(original[checkboxProps.selectorKey], original[checkboxProps.selectorKey1], original.membersnik)}
                        />
                    )
                }
            },
            {
                Header: 'Team',
                accessor: 'teamcode',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Sales ID',
                accessor: 'membersnik',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Sales Name',
                accessor: 'memberfullname',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Month',
                // accessor: 'outlet.outletAddress',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    return `${original.month}/${original.year}`
                }
            },
            {
                Header: 'Submit Date',
                accessor: 'submitdate',
                sortable: false,
                headerStyle,
                style,
                // Cell: ({ row }) => format(parseISO(row.values.date), 'dd/MM/yyyy')
                Cell: ({ row }) => formatDate(row.submitdate, 'dd/MM/yyyy')
            },
            {
                Header: 'Status',
                accessor: 'statusraw',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => (
                    <span className={` ${original.status === "2" ? "text-blue-500" : "text-black"} `}>{original.statusraw}</span>
                )
            },
            {
                Header: 'Action',
                accessor: 'planningid',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    const {
                        id,
                        mode,
                        status,
                        memberNik,
                        memberSalesTypeCode,
                        memberRayonTypeCode,
                        memberRayonCode,
                        memberpositionId,
                        memberSalesTypeId,
                        membeRayonTypeId,
                        memberTypeCode,
                        territoryCode,
                    } = {
                        id: original.planningid,
                        mode: SCREEN_TYPE.VIEW,
                        status: original.status,
                        memberNik: original?.membersnik,
                        memberSalesTypeCode: original?.memberSalesTypeCode,
                        memberRayonTypeCode: original?.memberRayonTypeCode,
                        memberRayonCode: original?.memberRayonCode,
                        memberpositionId: original?.memberpositionId,
                        memberSalesTypeId: original?.memberSalesTypeId,
                        membeRayonTypeId: original?.membeRayonTypeId,
                        memberTypeCode: original?.memberTypeCode,
                        territoryCode: original?.salesorg,
                    };
                    return (
                        <ButtonCustom onClick={() => handleClickCallBack({ id, mode, status, memberNik, memberSalesTypeCode, memberRayonTypeCode, memberRayonCode, memberpositionId, memberSalesTypeId, membeRayonTypeId, memberTypeCode, territoryCode })}>
                            Details
                        </ButtonCustom>
                    )
                }
            }
        ],
        callPlanningRequest: [
            {
                Header: 'Team',
                accessor: 'teamcode',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Sales ID',
                accessor: 'membersnik',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Sales Name',
                accessor: 'memberfullname',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Month',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    return `${original.month}/${original.year}`
                }
            },
            {
                Header: 'Submit Date',
                accessor: 'submitdate',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ row }) => formatDate(row.submitdate, 'dd/MM/yyyy')
            },
            {
                Header: 'Status',
                accessor: 'statusraw',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => (
                    <span className={`text-black ${original.status === "5" ? "text-green-500" : "text-red-500"} `}>{original.statusraw}</span>
                )
            },
            {
                Header: 'Action',
                accessor: 'callPlanningId',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    const {
                        id,
                        mode,
                        status,
                        memberNik,
                        memberSalesTypeCode,
                        memberRayonTypeCode,
                        memberRayonCode,
                        memberpositionId,
                        memberSalesTypeId,
                        membeRayonTypeId,
                        memberTypeCode,
                        territoryCode,
                    } = {
                        id: original.planningid,
                        mode: SCREEN_TYPE.APPROVED,
                        status: original.status,
                        memberNik: original?.membersnik,
                        memberSalesTypeCode: original?.memberSalesTypeCode,
                        memberRayonTypeCode: original?.memberRayonTypeCode,
                        memberRayonCode: original?.memberRayonCode,
                        memberpositionId: original?.memberpositionId,
                        memberSalesTypeId: original?.memberSalesTypeId,
                        membeRayonTypeId: original?.membeRayonTypeId,
                        memberTypeCode: original?.memberTypeCode,
                        territoryCode: original?.salesorg,
                    };
                    return (
                        <ButtonCustom onClick={() => handleClickCallBack({ id, mode, status, memberNik, memberSalesTypeCode, memberRayonTypeCode, memberRayonCode, memberpositionId, memberSalesTypeId, membeRayonTypeId, memberTypeCode, territoryCode })}>
                            Details
                        </ButtonCustom>
                    )
                }
            }
        ],
        coachingPlanning: [
            {
                Header: () => {
                    console.log("selectedData", selectedData);
                    return (
                        <input
                            type='checkbox'
                            checked={selectedData?.all === 1}
                            indeterminate={selectedData?.all === 2 ? "true" : "false"}
                            onChange={() => handleSelectedAllRows()}
                        />
                    )
                },
                accessor: '',
                width: 50,
                sortable: false,
                headerStyle,
                className: '', // belum di isi
                Cell: ({ original }) => {
                    const handleChange = (teamId, flmNik, isChecked) => {
                        if (isChecked) {
                            setSelectedFlmNiks([...selectedFlmNiks, flmNik]);
                            setSelectedTeamIds([...selectedTeamIds, teamId]);
                        } else {
                            setSelectedFlmNiks(selectedFlmNiks.filter(id => id !== flmNik));
                            setSelectedTeamIds(selectedTeamIds.filter(id => id !== teamId));
                        }
                    }
                    return (

                        <input
                            type='checkbox'
                            checked={selectedData.item[original[checkboxProps.selectorKey]] === true}
                            onChange={(e) => { 
                                const isChecked = e.target.checked;
                                handleSelectRow(original[checkboxProps.selectorKey], original.flmNik, original.memberPositionId); 
                                handleChange(original.memberPositionId, original.flmNik, isChecked); 
                            }}
                        />
                    )
                }
            },
            {
                Header: 'Team',
                accessor: 'teamName',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'FLM ID',
                accessor: 'flmNik',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'FLM Name',
                accessor: 'flmName',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Month',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    return `${original.month}/${original.year}`
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => (
                    <span className={` ${original.status === "Waiting" ? "text-blue-500" : "text-black"} `}>{original.status}</span>
                )
            },
            {
                Header: 'Action',
                accessor: 'coachingplanningid',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    const {
                        id,
                        mode,
                        status,
                        flmNik,
                        flmName,
                        month,
                        year,
                        memberSalesTypeCode,
                        memberRayonTypeCode,
                        memberRayonCode,
                        memberpositionId,
                        memberSalesTypeId,
                        membeRayonTypeId,
                        memberTypeCode,
                        territoryCode,
                    } = {
                        id: original.teamid,
                        mode: SCREEN_TYPE.VIEW,
                        status: original.status,
                        flmNik: original?.flmNik,
                        flmName: original?.flmName,
                        month: original?.month,
                        year: original?.year,
                        memberSalesTypeCode: original?.memberSalesTypeCode,
                        memberRayonTypeCode: original?.MemberRayonTypeCode,
                        memberRayonCode: original?.memberRayonCode,
                        memberpositionId: original?.memberPositionId,
                        memberSalesTypeId: original?.MemberSalesTypeId,
                        membeRayonTypeId: original?.memberRayonTypeId,
                        memberTypeCode: original?.memberTypeCode,
                        territoryCode: original?.SalesOrg,
                    };
                    return (
                        <ButtonCustom onClick={() => handleClickCallBack({ id, mode, status, flmNik, flmName, month, year, memberSalesTypeCode, memberRayonTypeCode, memberRayonCode, memberpositionId, memberSalesTypeId, membeRayonTypeId, memberTypeCode, territoryCode })}>
                            Details
                        </ButtonCustom>
                    )
                }
            }
        ],
        coachingPlanningRequest: [
            {
                Header: 'Team',
                accessor: 'teamName',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'FLM ID',
                accessor: 'flmNik',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'FLM Name',
                accessor: 'flmName',
                sortable: false,
                headerStyle,
                style,
            },
            {
                Header: 'Month',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    return `${original.month}/${original.year}`
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => (
                    <span className={`text-black ${original.status === "Approved" ? "text-green-500" : "text-red-500"} `}>{original.status}</span>
                )
            },
            {
                Header: 'Action',
                accessor: 'callPlanningId',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    const {
                        id,
                        mode,
                        status,
                        flmNik,
                        flmName,
                        month,
                        year,
                        memberSalesTypeCode,
                        memberRayonTypeCode,
                        memberRayonCode,
                        memberpositionId,
                        memberSalesTypeId,
                        membeRayonTypeId,
                        memberTypeCode,
                        territoryCode
                    } = {
                        id: original.planningid,
                        mode: SCREEN_TYPE.APPROVED,
                        status: original.status,
                        flmNik: original?.flmNik,
                        flmName: original?.flmName,
                        month: original?.month,
                        year: original?.year,
                        memberSalesTypeCode: original?.memberSalesTypeCode,
                        memberRayonTypeCode: original?.MemberRayonTypeCode,
                        memberRayonCode: original?.memberRayonCode,
                        memberpositionId: original?.memberPositionId,
                        memberSalesTypeId: original?.MemberSalesTypeId,
                        membeRayonTypeId: original?.memberRayonTypeId,
                        memberTypeCode: original?.memberTypeCode,
                        territoryCode: original?.SalesOrg,
                    };
                    return (
                        <ButtonCustom onClick={() => handleClickCallBack({ id, mode, status, flmNik, flmName, month, year, memberSalesTypeCode, memberRayonTypeCode, memberRayonCode, memberpositionId, memberSalesTypeId, membeRayonTypeId, memberTypeCode, territoryCode })}>
                            Details
                        </ButtonCustom>
                    )
                }
            }
        ],
    }
}


export default useFieldRowTwo;