import React, { useState } from 'react'
import close from '../../assets/icons/close.svg'
// import { toCurrency } from '../../utils/common'

const ProductDetailPopup = (props) => {
	const [popup, setpopup] = useState(false)
	const handlePopup = () => {
		setpopup(!popup)
	}

	return (
		<>
			<span className="cursor-pointer text-secondary hover:underline" onClick={handlePopup} >{props.data === undefined ? "Product Name" : props.data.productName}</span>
			<div className={` z-40 fixed w-full h-full flex flex-wrap items-center justify-center top-0 left-0 py-10 ${!popup ? "hidden" : ""}`} style={{ backgroundColor: "rgba(0,0,0,0.5)", fontSize: 16 }}>

				<div className="w-10/12 p-6 bg-white rounded">
					<div className="flex flex-wrap items-center justify-end ">
						<div className="w-4 py-1 text-sm text-center text-white rounded-full cursor-pointer" onClick={handlePopup}>
							<img src={close} alt="close" />
						</div>
					</div>

					<div className="max-w-screen-xl py-2 mx-auto md:p-4">
						<div className="flex flex-col bg-white rounded lg:flex-row ">
							<div className="flex flex-col md:flex-row lg:w-7/12">
								<div className="flex md:w-1/2">
									<div className="flex flex-col bg-gray-200 w-full gap-y-2 overflow-y-auto" style={{ maxHeight: 500 }}>
										{props.data.ProductImage?.length > 0 && (
											props.data.ProductImage.map(item => (
												<img className="rounded" key={item.productImageId} src={item.image} alt="" />
											))
										)}
									</div>
								</div>
								<div className="mt-5 text-left md:ml-5 md:mt-0">
									<div className="block font-bold">{props.data === undefined ? "Product Name" : props.data.productName}</div>
									{/* <div>{props.data === undefined ? "Rp. 90000" : `Rp. ${toCurrency(parseInt(props.data.hnaPrice))}`}</div> */}
									<div>{props.data.currency} {props.data.price}</div>
								</div>
							</div>
							<div className="flex flex-col flex-wrap mt-10 ml-10 text-left gap-y-5 lg:w-5/12 lg:mt-0">
								<div className="flex flex-col">
									<div className="font-bold">Composition</div>
									<div className="">{props.data === undefined ? "Product Composition" : props.data.productComposition}</div>
								</div>
								<div className="flex flex-col">
									<div className="font-bold">Benefit</div>
									<div className="">{props.data === undefined ? "Product Benefit" : props.data.productBenefit}</div>
								</div>
								<div className="flex flex-col mt-10">
									<div className="font-bold">How To Use</div>
									<div className="">{props.data === undefined ? "How To Use" : props.data.productHowToUse}</div>
								</div>
							</div>
						</div>
					</div>


				</div>
			</div>
		</>
	)
}

export default ProductDetailPopup
