import sessionStorage from 'redux-persist/lib/storage/session'
import { parseJwt } from '../../utils/common'

export const currentUser = (jwtToken = '', idtokenokta) => {
  return async dispatch => {
    try {
      let user = jwtToken ? parseJwt(jwtToken) : {};
      let tempUser = {
        ...user,
        memberEmail: user?.userEmail,
        memberFullname: user?.userFullname,
        memberId: user?.userId,
        memberNik: user?.userNik,
        memberType: user?.userType,
        token: jwtToken,
        idtokenokta: idtokenokta
      };
      delete user.userEmail;
      delete user.userFullname;
      delete user.userId;
      delete user.userNik;
      delete user.userType;

      dispatch({
        type: 'SIGN_IN',
        payload: { data: tempUser }
      })
      return tempUser
    } catch (error) {
      console.log('error', error)
    }
  }
}

export const logoutSSO = ({idtokenokta}) => {
  return async dispatch => {
    let myNaiveUrl = new URL(process.env.REACT_APP_BASE_URL_SSO + '/api/v1/ssologout');
    let objParams = {
      url_callback: window.location.protocol + "//" + window.location.host + '/',
      id_token: idtokenokta
    };
    myNaiveUrl.searchParams.append('url_callback', objParams.url_callback);
    myNaiveUrl.searchParams.append('id_token', objParams.id_token);

    sessionStorage.removeItem('persist:root');
    dispatch({
      type: 'SIGNOUT'
    })
    window.location.replace(myNaiveUrl.href);
  }
}

export const getProfileList = ({ type, payload }) => {
  return dispatch => {
      dispatch({ type, payload })
  }
}

export const updatedPositionList = ({ type, payload }) => {
  return dispatch => {
      dispatch({ type, payload })
  }
}

export const positionData = ({ type, payload }) => {
  return dispatch => {
      dispatch({ type, payload })
  }
}
export const positionAllData = ({ type, payload }) => {
  return dispatch => {
      dispatch({ type, payload })
  }
}
export const positionHierarchyData = ({ type, payload }) => {
  return dispatch => {
      dispatch({ type, payload })
  }
}
export const resetStore = ({ type, payload }) => {
  return dispatch => {
      dispatch({ type, payload })
  }
}