import { callPlanningManagerConstant } from "../constants/actionTypes"

const initialState = {
	lstApproved: [],
	lstRequest: [],
}

export const CallPlanningManagerReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case callPlanningManagerConstant.MANAGER_DATA: {

			return {
				...state,
				lstApproved: payload.data.success ? { ...payload.data.data } : []
			}
		}
		case callPlanningManagerConstant.MANAGER_REQUEST_DATA: {
			return {
				...state,
				lstRequest: payload.data.success ? { ...payload.data.data } : []
			}
		}
		case 'GET_CAll_PLAN_STATUS': {
			return {
                ...state,
                lstApproved: payload
            }
        }
		case 'GET_CAll_PLAN_APPROVAL': {
            return {
                ...state,
				lstRequest : payload
            }
        }
		default: return state
	}
}