import axios from "axios";
import { store } from '../redux/store'
import { logoutSSO } from "../redux/action/auth.action";

let reduxStore;
const UNAUTHORIZED = [401, 403];
const { dispatch } = store;

export const getReduxStore = () => {
    return reduxStore;
}

export const injectStore = _store => {
    reduxStore = _store
}

export const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-type": "application/json",
    }
});

const downloadInstance = (responseType) => {
    return axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: responseType
    })
}

export const bufferResponseInstance = downloadInstance('blob');

bufferResponseInstance.interceptors.request.use(function (config) {
    let token = getReduxStore().getState().auth.user.token;
    config.headers.Authorization = token ? `Bearer ` + token : null;

    return config;
});

bufferResponseInstance.interceptors.response.use(
    (response) => {
        return response;
    }, error => {
        if (UNAUTHORIZED.indexOf(error.response?.status) !== -1) {
            let idtokenokta = getReduxStore().getState().auth.user.idtokenokta;
            dispatch(logoutSSO({ idtokenokta }));
        }

        return Promise.reject(error)
    }
);

instance.interceptors.request.use(function (config) {
    let token = getReduxStore().getState().auth.user.token;
    config.headers.Authorization = token ? `Bearer ` + token : null;

    return config;
});

instance.interceptors.response.use(
    (response) => {
        return response;
    }, error => {
        if (UNAUTHORIZED.indexOf(error.response?.status) !== -1) {
            let idtokenokta = getReduxStore().getState().auth.user.idtokenokta;
            dispatch(logoutSSO({ idtokenokta }));
        }

        return error.response;
    }
);