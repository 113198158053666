import React from 'react'
import useFieldRow from './useFieldRow'
import useTableBody from './useTableBody'
import 'react-table-v6/react-table.css'

const useTable = (props) => {
  const {
    data,
    PresentationddData,
    checkboxProps,
    selectedData,
    handleSelectedData,
    handleChangeDate,
    handleSelectedData2,
    handleChangeQty,
    handleChangeJkn, 
    handleChangePresentationMaterial,
    handleAddProduct,
    handleDelProduct,
    handleChangeReasonNoOrder,
    handleChangeNotesNoOrder,
    handleOutlet,
    handlePromo,
    handleChange,
    ReasonforNoOrderVisiblity,
    handleDetailTarget,
    handleEditTarget,
    handleEditDetailTarget,
    handleClickCallBack,
    detail,
    setSelectedFlmNiks,
setSelectedTeamIds,
selectedFlmNiks,
selectedTeamIds,
  } = props
  const {
    renderTable,
    handleSelectedAllRows,
    handleSelectRow,
  } = useTableBody(props)
  const {
    fieldsRowTable
  } = useFieldRow({
    handleSelectedAllRows,
    handleSelectRow,
    handleChangeDate,
    handleSelectedData,
    handleSelectedData2,
    checkboxProps,
    selectedData,
    handleChangeQty,
    handleChangeJkn,
    handleChangePresentationMaterial,
    handleAddProduct,
    handleDelProduct,
    handleChangeReasonNoOrder,
    handleChangeNotesNoOrder,
    handleOutlet,
    handlePromo,
    handleChange,
    ReasonforNoOrderVisiblity,
    PresentationddData,
    handleDetailTarget,
    handleEditTarget,
    handleEditDetailTarget,
    handleClickCallBack,
    detail,
    setSelectedFlmNiks,
    setSelectedTeamIds,
    selectedFlmNiks,
    selectedTeamIds,
  })

  return (
    <div className='overflow-x-auto'>
      {renderTable(data, fieldsRowTable)}
    </div>
  )
}

export default useTable
