export const ROLE = {
  VIEW: 0,
  EDIT: 1,
  DELETE: 2,
}

export const DAY_TYPE = {
  WEEKDAYS: 0,
  WEEKENDS: 1,
  HOLIDAYS: 2,
}

export const SCREEN_TYPE = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
  APPROVED: 'approved',
}

export const DATE_FORMAT = {
  YY_MM: 'yyyy-MM-dd',
  DD_MM_YYY_HH_MM: 'dd-MM-yyyy HH:mm',
  DD_MM_YYY: 'dd-MM-yyyy',
  HH_MM: 'HH:mm',
  MM: 'MM',
  M: 'M',
  YYYY: 'yyyy'
};

export const MemberType = {
  FSS: 'FSS',
  FLM: 'FLM'
}

export const MemberMenuUrl = {
  ALL: 'ALL',
  REPORTS: 'reports',
  ACCOUNT: 'account',
  PRODUCT_LIST: 'product-list',
  CUSTOMER_VISIT: 'customer-visit',
  VISIT_PLAN_TEAM: 'visit-plan-team',
  SALESMAN_ACTIVITY: 'salesman-activity',
  TARGET_MANAGEMENT: 'target-management',
  CUSTOMER_MANAGEMENT: 'customer-management',
  COLLECTION_COLLECTED: 'collection-collected',
  COACHING_MODULE: 'coaching-module',
  CALL_PLANNING: 'call-planning',
  CALL_PLANNING_REQUEST: '/call-planning/manager',
  CALL_PLANNING_MODULE: 'call-planning-module',
  AVR: 'avr',
  DOWNLOAD: 'download',
  FLM_ACKNOWLEDGMENT: 'flm-coaching-acknowledge',
  //FLM_ACKNOWLEDGMENT: 'coaching-acknowledgement',
  DASHBOARD: 'dashboards',
  DOCUMENT_LIBRARY: '/document_library',
  SLMCALENDAR: '/slmcalendar'
};

export const EngageConstant = {
  AVR: 'avr',
  OTHERS: 'Others',
  MANUALLY: 'Manually',
  YES: 'Yes',
  NO: 'No',
  VIRTUAL_CALL: 'Virtual Call',
  EZRX: 'eZRx',
  RECOMMEND_TYPE_ICO: 'Sales Recommendation based from ICO',
  RecommenType: {
    ProductEnlistment: 'Product Enlistment',
    PromotionalActivity: 'Promotional Activity',
    RecommendationICO: 'Sales Recommendation based from ICO',
  },
  ACTIVITY: [
    { id: 1, name: 'Customer' },
    { id: 2, name: 'Otherwork' },
  ],
  MSG_SELECT_DAY: {
    HOLIDAY: 'The selected date is a Holiday. Do you still want to schedule a call ?',
    WEEKLY_OFF: 'The selected date is a Weekly Off day. Do you want to schedule a call ?'
  }
}

export const DIALOG = {
  CONFIRM: 0,
  WARNING: 1,
  ERROR: 2
}

export const DropDown = {
  YES_NO: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ],
  YES_NO_FOR_TELESELLER: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'Reco Agreed Customer to Order', label: 'Reco Agreed Customer to Order' },
  ],
  PLACE_ORDER: [
    { value: 'eZRx', label: 'eZRx' },
    { value: 'Manually', label: 'Manually' },
  ],
  MAKING: [
    { value: 'Manually', label: 'Manually' },
    { value: 'Local System', label: 'Local System' },
    { value: 'Face To Face', label: 'Face To Face' },
  ],
  MARKETS: [
    { value: 'Phone', label: 'Phone' },
    { value: 'Email', label: 'Email' },
  ],
  REASON: [
    { value: 'No data', label: 'No data' },
    { value: 'Slow connection', label: 'Slow connection' },
  ],
  DESIGNATION: [
    { value: 'Owner', label: 'Owner' },
    { value: 'Purchaser', label: 'Purchaser' },
    { value: 'Pharmacist', label: 'Pharmacist' },
    { value: 'Others', label: 'Others' },
  ],
  VIRTUAL: [
    { value: 'MSTeams', label: 'MSTeams' },
    { value: 'Zoom', label: 'Zoom' },
    { value: 'Phone Call', label: 'Phone Call' },
    { value: 'Others', label: 'Others' },
  ],
  DAY: [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
  ],
  DAY_TYPE: [
    { value: 'Full Day', label: 'Full Day' },
    { value: 'Half Day', label: 'Half Day' },
  ],
  RATING: [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 0, label: 'Not Applicable' },
    { value: -1, label: 'Not Observed' },
  ]
}

export const MESSAGE = {
  ACCOUNT_CHANGE_PASS: {
    VALID: 'Password must be between 8-20 char, containing min 1 upper and 1 lower letter, and 1 number.',
    NOT_MATCH: 'Password not match.',
    CURRENT_PASS_REQUIRED: 'Input current password required!',
    NEW_PASS_REQUIRED: 'Input new password required!',
    CONFIRM_NEW_REQUIRED: 'Input confirm password required!',
  },
  CHANGE_PASS: {
    VALID: 'Password must be between 8-20 char, containing min 1 upper and 1 lower letter, and 1 number.',
    NOT_MATCH: 'Password not match.',
    CURRENT_PASS_REQUIRED: 'Input current password required!',
    NEW_PASS_REQUIRED: 'Input new password required!',
    CONFIRM_NEW_REQUIRED: 'Input confirm password required!',
  },
  COLLECTION_COLLECTED: {
    DIH_SELECTED: 'DIH No has not been selected'
  },
  COLLECTION_COLLECTED_ADITIONAL_LIST: {
    SELECTED_GL_ACCOUT: 'please select GL Account',
    SELECTED_POSTING_KEY: 'please select Posting Key',
    SELECTED_TEXT_COST: 'please input text cost',
    SELECTED_TAX_COST: 'please input tax cost',
    SELECTED_TAX_CODE: 'please input tax code',
  },
  COLLECTION_COLLECTED_INVOICE_LIST: {
    PAYMENT_VALUE: 'Payment Value tidak boleh lebih besar dari pada Invoice Value'
  },
  CUSTOMER_VISIT_CREATE: {
    CUSTOMER_EXIST: 'Customer already exist in this date',
  },
  CUSTOMER_VISIT_CUSTOMER_SURVEY_DETAIL: {
    NOT_ANSWERED: 'all questions have not been answered!'
  },
  CUSTOMER_VISIT_RETURN_PRODUCT: {
    INVOICE_NO_REQUIRED: 'Invoice No is required!',
    TYPE_RETURN_REQUIRED: 'Type return is required!',
    REASON_RETURN_REQUIRED: 'Reason return name is required!'
  },
  CUSTOMER_VISIT_VIRTUAL_FORM: {
    SELECTED_IMAGES: 'Please select images',
    INPUT_REMARKS: 'Please input remarks',
  },
  VISIT_PLAN_TEAM: {
    REQUEST_SELECTED: 'No items request selected'
  },
  CUSTOMER_VISIT_COLLECTION: {
    UPDATE_PAYMENT: 'Please update payment schedule in Invoice No ',
    SELECTED_REASON: 'Please select reason category',
    FILL_NOTE: 'Please fill the note',
  },
  CUSTOMER_VISIT_RESCHEDULE: {
    REMARK_REQUIRED: 'Remark schedule required',
    RE_ENTER_REQUIRED: 'Re-enter date required',
    SELECTED_REASON: 'Select reason has not been selected',
    SELECTED_TABLE_SCHEDULE: 'table schedule has not been selected',
  },
  CUSTOMER_VISIT_SELLING_DETAILING: {
    SELECTED_CATEGORY: 'Please select reason category',
    FILL_NOTE: 'Please fill the notes',
    PRODUCT_OUT_OF_STOCK: 'Product out of stock',
    PRODUCT_LIMIT: 'Product limit'
  },
  AVR: {
    PRODUCT_REQUIRED: 'Product/SKUs is required',
    LISTED_REQUIRED: 'Listed is required',
    SHELF_STOCK_REQUIRED: 'Shelf Stock is required',
    LOCATION_REQUIRED: 'Location is required',
    LAYED_REQUIRED: 'Layer is required',
    SHELF_SPACE_REQUIRED: 'Shelf Space is required',
    NO_OF_PRODUCT_FACINGS_REQUIRED: 'No of Product Facings is required',
    REMARKS_REQUIRED: 'Remarks is required',
    START_DATE_REQUIRED: 'Start Date is required',
    END_DATE_REQUIRED: 'End Date is required',
    END_DATE_GRATER_START_DATE: 'End Date must be grater than Start Date',
    ICO_REQUIRED: 'ICO is required',
    LIMIT_IMAGES: 'Images at least 1'
  },
  CALL_PLANNING: {
    SELECTED_HAFT_DAY: 'Select Other Activities to fulfill day work',
    CHANGE_DATE_NOT_YET_SAVE: 'This Day Plan is unsaved. If you go to another day, the Day Plan will be removed. Do you want to continue?',
    CLOSE_NOT_YET_SAVE: 'Do you want to close the screen? All unsaved planned activities will be removed.',
    FULFILLED: 'Your working day was fulfilled, you cannot choose other activities',
    TWO_HALF_DAY: 'You cannot add two half days. Uncheck half day',
    CONFIRM_FULL_DAY: 'Full Day Leave is selected. Do you want to add other activity instead?',
    CONFIRM_ADD_FULL_DAY: 'All planned activities will be removed. Do you want to continue?',
    SEND_FOR_APPROVE: 'Must plan Activities for all working days to send for Approval'
  },
  FLM_COACHING: {
    MAX_LIMIT_IMAGES: 'Images at least 2',
    MIN_LIMIT_IMAGES: 'Images at least 2'
  }
}

export const CallPlanningConstant = {
  TAB: {
    CALL_PLANNING: 0,
    CALL_PLANNING_REQUEST: 1,
  },
  TAB1: {
    FIELD_WORK: 1,
    OTHERWORK: 2,
  },
  MENU: {
    CUSTOMER: 0,
    OTHER: 1,
  },
  CUSTOMER_TYPE: {
    CUSTOMER: {
      id: 1,
      name: 'Customer'
    },
    OTHERWORK: {
      id: 0,
      name: 'OtherWork'
    },
  },
  STATUS: {
    NOT_CREATED: {
      value: 0,
      name: 'Not Created'
    },
    DRAFT: {
      value: 1,
      name: 'Draft'
    },
    WATTING: {
      value: 2,
      name: 'Waiting'
    },
    REVIEWING: {
      value: 3,
      name: 'Reviewing'
    },
    REJECT: {
      value: 4,
      name: 'Rejected'
    },
    APPROVED: {
      value: 5,
      name: 'Approved'
    },
  },
  MESSAGE: {
    APPROVED: 'Approve call plan request',
    REJECT: 'Reject call plan request'
  },
  COACHING_PLANNING_MESSAGE: {
    APPROVED: 'Approve Coaching Plan',
    REJECT: 'Reject Coaching Plan'
  }
}

export const CallReportConstant = {
  CALL_REPORT_MENU: {
    CALL_DETAILS: {
      url: 'call-details',
      label: 'Call Detailed'
    },
    CALL_ANALYSIS: {
      url: 'call-analysis',
      label: 'Call Analysis'
    },
    CUSTOMER_COVERAGE: {
      url: 'customer-coverage-day',
      label: 'Call Coverage Per day'
    },
    CUSTOMER_SUMMARY: {
      url: 'customer-summary',
      label: 'Customer Visit Summary '
    },
    TARGET_CALL: {
      url: 'target-call',
      label: 'Target Call '
    },
    CUSTOMER_STOCK_REPORT: {
      url: 'customer-stock-record',
      label: 'Customer Stock Record'
    },
    CUSTOMER_SURVEY_REPORT: {
      url: 'customer-survey-report',
      label: 'Customer Survey Report'
    },
    TENDER_PROCESS_REPORT: {
      url: 'tender-detailed-report',
      label: 'Tender Detailed Report'
    },
    PRESENTATION_REPORT: {
      url: 'Presentation-report',
      label: 'Presentation Report'
    },
  },
  ACTION: {
    GET: 0,
    ADD: 1
  },
  NAME: {
    BRANCH: 'branch',
    ASM: 'memberAsmNik',
    FSS: 'memberFssNik',
    SLM: 'memberSlmNik',
    SR_TYPE: 'srType',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    TEAM: 'teamCode',
  }
}

export const CallPlanReportConstant = {
  CALL_PLAN_REPORT_MENU: {
    CALL_PLAN_SUMMARY: {
      url: 'call-plan-summary',
      label: 'Call Plan Summary'
    },
    CALL_PLAN_DETAILED: {
      url: 'call-plan-detailed',
      label: 'Call Plan Detailed'
    },
  },
}

export const FlmReportConstant = {
  FLM_REPORT_MENU: {
    FLM_COACHING_DETAILS: {
      url: 'flm-coaching-details',
      label: 'FLM Coaching Details'
    },
    DAILYFILED_COACHING: {
      url: 'flm-coaching-report',
      label: 'FLM Coaching Report'
    },
    FLM_DAILYFILED_COACHING: {
      url: 'flm-coaching-detailed-report',
      label: 'FLM Coaching Detailed'
    },
    FLM_PLAN_REPORT: {
      url: 'flm-plan-report',
      label: 'FLM Plan Report'
    },
    FLM_PENDING_COACHING_REPORT: {
      url: 'flm-pending-report',
      label: 'Pending FLM Coaching Report'
    },
    FLM_COACHING_ACKNOWLEDGEMENT_REPORT: {
      url: 'flm-coachingAcknowledgement-report',
      label: 'Coaching Acknowledgement Report'
    },
    FLM_TERRITORIAL_COVERAGE_REPORT: {
      url: 'tc-report',
      label: 'Territorial Coverage Report'
    },
  }
}
export const SlmReportConstant = {
  SLM_REPORT_MENU: {
    SLM_COACHING_DETAILS: {
      url: 'slm-coaching-details',
      label: 'SLM Coaching Details'
    },
    DAILYFILED_COACHING: {
      url: 'slm-coaching-report',
      label: 'SLM Coaching Report'
    },
    SLM_DAILYFILED_COACHING: {
      url: 'slm-coaching-detailed-report',
      label: 'SLM Coaching Detailed'
    },
    SLM_PLAN_REPORT: {
      url: 'slm-plan-report',
      label: 'SLM Plan Report'
    },
    SLM_PENDING_COACHING_REPORT: {
      url: 'slm-pending-report',
      label: 'Pending SLM Coaching Report'
    },
    SLM_COACHING_ACKNOWLEDGEMENT_REPORT: {
      url: 'slm-coachingAcknowledgement-report',
      label: 'Coaching Acknowledgement Report'
    },
  }
}
export const TeleSellerReportConstant = {
  TELE_SELLER_REPORT_MENU: {
    TELE_SELLER_REPORT: {
      url: 'tele-seller-report',
      label: 'Teleseller Report'
    },
  }
}
export const ConsignmentReportConstant = {
  CONSIGNMENT_REPORT_MENU: {
    CONSIGNMENT_REPORT: {
      url: 'consignment-report',
      label: 'Consignment Report'
    },
  }
}

export const UtilityReportConstant = {
  UTILITY_REPORT_MENU: {
    LOG_DETAILS_REPORT: {
      url: 'log-details-report',
      label: 'Log Details Report'
    },
    FIELD_IMPRESSION: {
      url: 'field-impression-report',
      label: 'Field Impression Report'
    },
    APPLICATION_SYNC_REPORT: {
      url: 'application-sync-report',
      label: 'Application Sync Report'
    },
    POSITION_HIERARCHY_REPORT: {
      url: 'position-hierarchy-report',
      label: 'Position Hierarchy Report'
    }
  }
}

export const CustomerReportConstant = {
  CUSTOMER_REPORT_MENU: {
    CUSTOMER_REPORT: {
      url: 'customer-report',
      label: 'Customer Report'
    },
    LOCATION_MAPPED_REPORT: {
      url: 'location-mapped-report',
      label: 'Location Mapped Report'
    },
  }
}


export const MEMBER_TYPE_CODE_MANAGER = {
  Manager: [
    'SFE',
    'Regional SFE',
  ]
}
export const MEMBER_TYPE_CODE = {
  SLM: 'slm',
  FSS: 'fss',
  FLM: 'flm',
  ASM: 'asm',
  NSM: 'nsm',
  BUM: 'bum',
  SFE: 'sfe',
  REGIONAL_SFE: 'regional sfe',
  Salesman: 'salesman',
  Trade_Representative: 'Trade Representative',
  STE: 'ste',
  KAE: 'kae',
  TeleSalesRep: 'TeleSalesRep',
  Consignment_Representative: 'CR'
}

export const MEMBER_TYPE_CODE_SALESMAN = {
  Salesman: ['Salesman',
    "Trade Representative",
    "Type Salesman",
    "KAE",
    "Key Account Executive",
    "Strategic Trade Executive",
    "STE",
  ]

}

export const MEMBER_TYPE_CODE_TELESALES = {
  Telesales: ['TeleSalesRep',
    "FSS"
  ]

}

export const MEMBER_TYPE_CODE_FLM = {
  Flm: ['FLM',
    "FSS"
  ]

}

export const MEMBER_TYPE_CODE_SLM = {
  SLM: ['SLM',
    "ASM"
  ]

}

export const ACCOUNT_PROFILE_TEAMRAYON_HIDE = {
  TeamRayon: ["SFE",
    "Regional SFE",
    "ASM",
    "BUM",
    "NSM",
    "FSS",
    "Trade Representative",
    "TeleSalesRep",
    "CR"
  ]

}

export const COLOR = {
  BLUE: 'blue',
  GREEN: 'green',
  BLACK: 'black',
  YELLOW: 'yellow',
  FORIEGN: 'foriegn',
  PRIMARY: 'primary',
  RED: 'red'
}