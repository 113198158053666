import { combineReducers } from 'redux'
import { AuthReducer } from './auth.reducer'
import { MemberPriviledgeMenuReducer } from './memberPriviledge.reducer'
import { VisitPlanReducer } from './visitPlan.reducer'
import { CustomerVisitPlanReducer } from './customerVisitPlan.reducer'
import { MerchandiseReducer } from './merchandise.reducer'
import { InvoiceListByDih } from './collectionCollected.reducer'
import { CustomerSurveyReducer } from './customerSurvey.reducer'
import { avrSurveyReducer } from './avrSurvey.reducer'
import { orderTakingReducer } from './orderTaking.reducer'
import { CallPlanningReducer } from './callPlanning.reducer'
import { CallPlanningManagerReducer } from './callPlanningManager.reducer'
import { CallReportReducer } from './callReport.reducer'
import { FLMCoachingReducer, FLMPlanReducer } from './flm.reducer'
import { DocumentReducer } from './document.reducer'
import { TeleSalesReducer } from './teleSales.reducer'
import { TenderReducer } from './tender.reducer'


export const rootReducer = combineReducers({
  auth: AuthReducer,
  avrSurvey: avrSurveyReducer,
  visitPlans: VisitPlanReducer,
  arCollected: InvoiceListByDih,
  callReport: CallReportReducer,
  orderTaking: orderTakingReducer,
  merchandise: MerchandiseReducer,
  callPlanning: CallPlanningReducer,
  customerSurvey: CustomerSurveyReducer,
  customerVisitPlan: CustomerVisitPlanReducer,
  callPlanningManager: CallPlanningManagerReducer,
  memberPriviledgeMenu: MemberPriviledgeMenuReducer,
  flmCoaching: FLMCoachingReducer,
  flmPlan: FLMPlanReducer,
  DocumentReducer: DocumentReducer,
  TeleSalesReducer: TeleSalesReducer,
  TenderReducer: TenderReducer
})
