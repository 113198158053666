import React from 'react'
import ReactDOM from 'react-dom'
import './styles/tailwind.css'
import './i18n'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import 'leaflet/dist/leaflet.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { injectStore } from './utils/http-common'
import { injectOldStore } from './utils/request'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'

injectStore(store);
injectOldStore(store);

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div style={style}>
    <Alert onClose={close} severity={options.type}>{message}</Alert>
  </div>
)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          <AlertProvider template={AlertTemplate} {...options}>
            <Route path="/*" component={App} />
          </AlertProvider>
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
