import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";

const SelectInput = withStyles((theme) => ({
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: '#EFF0F7',
    border: '1px solid #D9DBE9',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    color: 'black',
    '&:focus': {
      borderRadius: 0
    }
  }
}))(InputBase)

const SelecOption = (props) => {
  const { value, onChange, name, children, isDisabled, multiple, defaultValue } = props
  return (
    <FormControl variant='outlined' style={{ width: '100%' }}>
      <Select
        name={name}
        value={value}
        displayEmpty
        input={<SelectInput />}
        onChange={onChange}
        disabled={isDisabled || false}
        multiple={multiple || false}
        defaultValue={defaultValue}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default SelecOption
