import React from 'react'
import Select from 'react-select';

const customStyles = {
  control: (base, state) => ({
    ...base,
    width: '100%',
    backgroundColor: "#EFF0F7",
    border: '1px solid #D9DBE9',
    boxShadow: null,
    appearance: 'none',
    borderColor: 'none',
    borderRadius: '0.375rem',
    "&:hover": {
      outLine: 'none',
      borderRadius: 'none'
    },

    borderStyle: 'none',
    borderWidth: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'white',
  }),
};

const InputSelect = (props) => {
  return (
    <Select
      {...props}
      styles={customStyles}
      components={{
        IndicatorSeparator: () => null
      }}
    />
  )
}

export default React.memo(InputSelect)
