import { useDispatch, useSelector } from 'react-redux';
import { currentUser } from '../redux/action/auth.action';

export default function useToken() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    const getToken = () => {
        return user?.token
    }

    const saveToken = async (userToken, idtokenokta) => {
        await dispatch(currentUser(userToken, idtokenokta))
    }

    return {
        setToken: saveToken,
        token: getToken
    }
}





