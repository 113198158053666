import { Redirect, Route } from "react-router-dom";
import useToken from "../../utils/useToken";

export function RequireAuth({ component: Component, ...rest }) {
    let storeTokenData = useToken();

    return (
        <Route
            {...rest}
            render={props => {
                if (!storeTokenData?.token()) {
                    return <Redirect
                        to={{
                            pathname: "/redirect",
                            state: { from: props.location }
                        }}
                    />
                }

                return <Component {...props} />
            }}
        />
    );
};



