import React from 'react'
import { createTheme } from '@material-ui/core/styles'
// import DateRangeIcon from '@material-ui/icons/DateRange'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/core/styles'
import Icon from "@material-ui/core/Icon";
import CalendarSvg from '../../assets/icons/calendar-stroke.svg'

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#157478',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#157478',
        padding: '5px'
      }
    },
    '& .MuiButtonBase-root': {
      padding: 0
    }
  }
})

const SelectDate = ({ value, onChange, format,style, minDate, maxDate, views, disabled, onError }) => {  
  const calendarIcon = (<Icon> <img alt="" src={CalendarSvg} style={{width: "90%"}}/></Icon>)

  const url = window.location.pathname;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          value={value}
          onChange={onChange}
          format={format || 'dd-mm-yyy'}
          InputProps={{
            disableUnderline: true,
            style: {
              color: '#00000',
              backgroundColor: '#D9DBE9',
              borderRadius: '4px',
              outline: 'none',
              padding: 6,
              ...style
            }
          }}
          disabled={disabled}
          minDate={lastPart === "tenderManagement" ? minDate : minDate || new Date()}
          maxDate={lastPart === "tenderManagement" ? maxDate : maxDate || new Date(8640000000000000)} // If empty, get maximum date value
          keyboardIcon={calendarIcon}
          views={views || ['date']} // ['date', 'month', 'year']
          onError={onError}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default SelectDate
