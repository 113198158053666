import axios from 'axios';
import { logoutSSO } from '../redux/action/auth.action';
import { store } from '../redux/store';

let reduxStore;
const UNAUTHORIZED = [401, 403];
const { dispatch } = store;

export const getReduxStore = () => {
  return reduxStore;
}

export const injectOldStore = _store => {
  reduxStore = _store
}

export const get = (url, options, success, handleError) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}${url}`, options)
    .then(response => {
      success(handleExpired(response));
    })
    .catch(error => {
      handleError(handleExpired(error.response))
    })
}

export const get_async = async (url, options, success, handleError) => {
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}${url}`, options)
    .then(response => {
      success(handleExpired(response));
    })
    .catch(error => {
      handleError(handleExpired(error.response))
    })
}

export const post = (url, options, data, success, handleError) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}${url}`, data, options)
    .then(response => {
      success(handleExpired(response));
    })
    .catch(error => {
      handleError(handleExpired(error.response))
    })
}

export const put = (url, options, data, success, handleError) => {
  axios
    .put(`${process.env.REACT_APP_BASE_URL}${url}`, data, options)
    .then(response => {
      success(handleExpired(response));
    })
    .catch(error => {
      handleError(handleExpired(error.response))
    })
}

export const del = (url, options, success, handleError) => {
  axios
    .delete(`${process.env.REACT_APP_BASE_URL}${url}`, options)
    .then(response => {
      success(handleExpired(response));
    })
    .catch(error => {
      handleError(handleExpired(error.response))
    })
}


const handleExpired = (response = {}) => {
  if (UNAUTHORIZED.indexOf(response.status) !== -1) {
    let idtokenokta = getReduxStore().getState().auth.user.idtokenokta;
    return dispatch(logoutSSO({ idtokenokta }));
  }

  return response;
}
