import Button from '@material-ui/core/Button'

const ButtonAction = ({
  variant,
  color,
  className,
  startIcon,
  children,
  onClick,
  fullWidth,
  disabled
}) => {
  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      className={className}
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled || false}
    >
      {children}
    </Button>
  )
}

export default ButtonAction
