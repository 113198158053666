const initialState = {
	merchandise: null
}

export const MerchandiseReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case 'MERCHANDISE': {
			return {
				...state,
				merchandise: { ...payload.data.data }
			}
		}
		default: return state
	}
}