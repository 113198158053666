const initialState = {
	priviledges: null
}

export const MemberPriviledgeMenuReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case 'MEMBER_PRIVILEDGES_MENU': {
			return {
				...state,
				priviledges: payload.data.success ? { 
					...payload.data.data
				} : null
			}
		}
		case 'SIGNOUT': {
			return {
				...state,
				priviledges: null
			}
		}
		default: return state
	}
}